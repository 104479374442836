import React, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { blueGrey, indigo } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import jsCookie from 'js-cookie';
import { Link } from 'react-router-dom';

export default function Cookie() {
    const [showModal, setShowModal] = useState(false);
    const [cookieAccepted, setCookieAccepted] = useState(false);

    useEffect(() => {
        const cookieAccepted = jsCookie.get('cookie_accepted');
        setCookieAccepted(cookieAccepted === 'true');
    }, []);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const acceptCookie = () => {
        jsCookie.set('cookie_accepted', 'true', { expires: 365 });
        setShowModal(false);
        setCookieAccepted(true);
    };

    const savePreference = () => {
        setShowModal(false);
    };

    const label = { inputProps: { 'aria-label': 'Switch cookie' } };
    const CommonSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: blueGrey[50],
            '&:hover': {
                backgroundColor: alpha(indigo[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: indigo[500],
        },
    }));

    return (
        <>
            {showModal && (
                <div className="modal">
                    <div className='model-title'><p>Privacy Preference Center</p><span className="close" onClick={closeModal}>&times;</span></div>
                    <div className="modal-content">
                        <div className='model-title-item'><p>Your Privacy</p></div>
                        <div className='model-title-item'><p>Strictly Necessary Cookies</p><CommonSwitch {...label} color="default" checked disabled /></div>
                        <div className='model-title-item'><p>Performance Cookies</p><CommonSwitch {...label} /></div>
                        <div className='model-title-item'><p>Functional Cookies</p><CommonSwitch {...label} /></div>
                        <div className='model-title-item'><p>Targeting Cookies</p><CommonSwitch {...label} defaultChecked /></div>
                        <hr />
                        <div className='save-button'>
                            <p onClick={savePreference}>Save Preference</p>
                        </div>
                    </div>
                </div>
            )}
            {!cookieAccepted && (
                <div className="cookie-container">
                    <div className="row display-container">
                        <div className="sm-col-7 cookie-container-left">
                            <p className='cookie-text'>
                                By clicking "Accept All Cookies",you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.</p>
                            <Link className="policy-text" to="/Policy">MR Xpress Cookie Policy</Link>
                        </div>
                        <div className="sm-col-5 cookie-container-right">
                            <div className="cookie-container-button button-setting" onClick={openModal}>
                                <p >Cookies Settings</p>
                            </div>
                            <div className="cookie-container-button button-accept">
                                <p onClick={acceptCookie} data-testid="accept-all-cookies-button">Accept All Cookies</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
