import React from 'react';

export default function RefundPolicy() {
    return (
        <div className="container legal-doc-styling">
            <h1>Refund and Cancellation Policy</h1>
            <p>
                MR Xpress Refund and Cancellation Policy<br/>
                    Effective Date: 21 May 2024
            </p>
            <p>
                MR Xpress Pty Ltd ("MR Xpress," "we," "us," or "our") aims to provide the best possible service to our customers. This Refund and Cancellation Policy outlines the terms and conditions for refunds and cancellations.
            </p>
            <div>
                <p class="section-title">1. Service Cancellation by Customer</p>
                <ul>
                    <li>Cancellation Before Technician Dispatch: Customers can cancel their service request before the technician has been dispatched. A full refund will be provided.</li>
                    <li>Cancellation After Technician Dispatch: If the technician has already been dispatched, a cancellation fee of [amount] will apply.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">2. Service Cancellation by MR Xpress</p>
                <ul>
                    <li>Cancellation Due to Unavailability: If MR Xpress is unable to provide the requested service, a full refund will be provided to the customer.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">3. Refunds</p>
                <ul>
                    <li>Eligibility for Refunds: Refunds are provided for services that are not completed due to technician error or if the service provided does not meet the agreed-upon standards.</li>
                    <li>Refund Process: To request a refund, customers must contact us at info@mrxpress.com.au within 30 days of the service date. The refund will be processed within [number] business days.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">4. Non-Refundable Services</p>
                <ul>
                    <li>Completed Services: No refunds will be provided for services that have been completed successfully.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">5. Changes to This Refund and Cancellation Policy</p>
                <ul>
                    <li>Amendments: We may update this Refund and Cancellation Policy from time to time. We will notify you of any changes by posting the new Refund and Cancellation Policy on our website.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">6. Contact Information</p>
                <p>For any questions or concerns about this Refund and Cancellation Policy, please contact us at info@mrxpress.com.au.</p>
            </div>
        </div>
    );
}
