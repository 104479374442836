export const GA_TRACKING_ID = 'G-99MXCNFTE0';

export const initGA = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    console.log('gtag arguments:', arguments);
    window.dataLayer.push(arguments);
  }
  window.gtag = window.gtag || gtag;
  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID);
};

export const trackEvent = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const trackPageview = (url) => {
  console.log('url:', url);
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};
