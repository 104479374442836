import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ButtonGroup, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {isStringEmpty} from '../utils/isStringEmpty.js';
import {isEmailValid} from '../utils/isEmailValid.js';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Api from '../utils/request/http.js';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import AddressAutoComplete from '../components/AddressAutoComplete.js';
import ReCAPTCHA from 'react-google-recaptcha';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import Loader from '../components/Loader.js';
import { isPhoneValid } from '../utils/isPhoneValid.js';
import config from '../config.js';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import FacebookIcon from '@mui/icons-material/Facebook';
import FooterInit from '../components/FooterInit.js';
import HeaderInit from '../components/HeaderInit.js';
import { Helmet } from 'react-helmet-async';

const ReCAPTCHA_SITE_KEY = config.recaptcha_key

const defaultTheme = createTheme();

const GrayButton = styled(Button)({
  color: '#ccc',
  borderTopColor: '#ccc',
  borderRightColor: '#ccc',
  borderBottomColor: '#ccc',
});

const countTime = 120;

export default function SignUp({setShowSidebar}) {

  const [showLoader, setShowLoader] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [errors, setErrors] = React.useState({});

  const [formData, setFormData] = React.useState({});

  const [captchaValue, setCaptchaValue] = React.useState(null);

  const recaptchaRef = React.useRef();

  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const [countdown, setCountdown] = React.useState(countTime);

  const [showPassword, setShowPassword] = React.useState(false);

  const [agreeToTerms, setAgreeToTerms] =  React.useState(false);
  const [agreeToSubscribe, setAgreeToSubscribe] =  React.useState(false);

  const handleTermsChange = (event) => {
    setAgreeToTerms(event.target.checked);
  };

  const handleSubscribeChange = (event) => {
    setAgreeToSubscribe(event.target.checked);
  };

  const facebookAppId = config.facebook_app_id;

  const onGoogleSuccess = async (res) => {
    // const data = new FormData();
    // const googleUser = jwtDecode(res.credential)
    // console.log(googleUser)
    
    // data.append('firstName', googleUser.given_name);
    // data.append('lastName', googleUser.family_name);
    // data.append('email', googleUser.email);
    // data.append('googleId', googleUser.googleId);

    setShowLoader(true);
    Api.post('/customer/registerWithGoogle', {jwt_token: res.credential})
        .then((response) => {
          console.log('User created:', response);
          setShowLoader(false);
          if (response.code !== 1000) {
            enqueueSnackbar(response.msg, { variant: 'error' });
          } else {
            enqueueSnackbar('Login in successfully', { variant: 'success' });
            Cookies.set('acc_token', response.data.access_token);
            let repairStep = JSON.parse(localStorage.getItem('repair_step'));
            window.location.href = '/Repair';
          }
        })
        .catch((error) => {
          setShowLoader(false);
          console.error('Error:', error);
          enqueueSnackbar('Network error', { variant: 'error' });
        });
  };

  const onGoogleFailure = (err) => {
    console.log('failed:', err);
    // enqueueSnackbar(err.message,  {variant: 'error'})
  };

  const responseFacebook = (response) => {
    console.log('Facebook user info:', response);    
    const { name, email, userID } = response;
    if (name && email && userID) {
       
   
        console.log('Name:', name);
        console.log('Email:', email);
        console.log('Facebook User ID:', userID);

        const data = new FormData();
        data.append('firstName', name);
        data.append('email', email);
        data.append('facebookId', userID);

        setShowLoader(true);
        Api.post('/customer/registerWithFacebook', data)
            .then((response) => {
                console.log('User created:', response);
                setShowLoader(false);
                if (response.code !== 1000) {
                    enqueueSnackbar(response.msg, { variant: 'error' });
                } else {
                    enqueueSnackbar('Login in successfully', { variant: 'success' });
                    Cookies.set('acc_token', response.data.access_token);
                    let repairStep = JSON.parse(localStorage.getItem('repair_step'));
                    window.location.href = '/Repair';
                }
            })
            .catch((error) => {
                setShowLoader(false);
                console.error('Error:', error);
                enqueueSnackbar('Network error', { variant: 'error' });
            });

        
    } else if (response.status === 'not_authorized') {
        
        console.log('user not authorized');
        
    } else {
        
        console.log('Facebook login fail:', response);
       
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleVerification = () => {
    if (!formData.email) {
      enqueueSnackbar('Please input your email', {variant: 'error'})
      return;
    }
    // Disable the button
    setButtonDisabled(true);

    Api.post('/customer/sendCode', {email: formData.email})
      .then(res => {
        if (res.code !== 1000) {
          enqueueSnackbar(res.msg,  {variant: 'error'})
          return;
        }
        enqueueSnackbar('Verification code has been sent to your email',  {variant: 'success'})
        startCountdown();
      })
      .catch(e => {
        setButtonDisabled(false);
        enqueueSnackbar('Network error',  {variant: 'error'})
      })
    
  };

  const startCountdown = () => {
    let timer = countTime;
    const countdownInterval = setInterval(() => {
      if (timer > 0) {
        timer -= 1;
        setCountdown(timer);
      } else {
        // Enable the button when the countdown reaches 0
        setButtonDisabled(false);
        clearInterval(countdownInterval);
        setCountdown(countTime);
      }
    }, 1000);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errMsg = {};
    console.log(formData)
    errMsg.firstName = isStringEmpty(formData.firstName) ? 'First Name is required' : '';

    errMsg.lastName = isStringEmpty(formData.lastName) ? 'Last Name is required' : '';
    
    errMsg.email = isStringEmpty(formData.email) ? 'Email is required' : '';
    if (!errMsg.email) {
        errMsg.email = !isEmailValid(formData.email) ? 'Email is not valid' : '';
    }

    errMsg.code = isStringEmpty(formData.code) ? 'Verification is required' : '';

    errMsg.phone = isStringEmpty(formData.mobile) ? 'Phone number is required' : '';
    if (!errMsg.phone) {
      errMsg.phone = !isPhoneValid(formData.mobile) ? 'Phone number is not valid' : '';
    }

    errMsg.password = isStringEmpty(formData.password) ? 'Password is required' : '';
    console.log(errMsg)
    setErrors({...errMsg});

    return Object.values(errMsg).every(item => item === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!agreeToTerms) {
      enqueueSnackbar('You must agree to the terms of service.', {variant: 'error'});
      return;
    }

    if (!captchaValue) {
      enqueueSnackbar('Please verify google reCaptcha',  {variant: 'error'})
      return;
    }

    if (!validateForm()) {
      enqueueSnackbar('Please fill in the form correctly',  {variant: 'error'})
      return;
    } 
    const data = new FormData(event.currentTarget);
    setShowLoader(true)
    Api.post('/customer/register', data)
      .then((data) => {
        console.log('User created:', data);
        if (data.code !== 1000) {
          setShowLoader(false)
          recaptchaRef.current.reset();
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          enqueueSnackbar('User created successfully',  {variant: 'success'})
          Cookies.set('acc_token', data.data.access_token);
          if (agreeToSubscribe == 'on') {
            localStorage.setItem('homeSubscribed', true);
          }
          let repairStep = JSON.parse(localStorage.getItem('repair_step'));
          window.location.href = '/Repair';
        }
      })
      .catch((error) => {
        setShowLoader(false)
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  React.useEffect(() => {
    setShowSidebar(0)
    return () => {
      clearInterval(startCountdown);
    }
  }, []);

  return (
    <>
    <Helmet>
      <title>Create an MR Xpress Account | Sign Up</title>
      <meta name="description" content="Sign up for an MR Xpress account to book repairs quickly, track your orders, and get exclusive offers on mobile repair services." />
      <meta name="keywords" content="sign up, register, phone repair, MR Xpress" />
      <meta property="og:title" content="Create an MR Xpress Account | Sign Up" />
      <meta property="og:description" content="Sign up for an MR Xpress account to book repairs quickly, track your orders, and get exclusive offers on mobile repair services." />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="MR Xpress"></meta>
      <meta property="og:url" content="https://www.mrxpress.com.au/SignUp" />
      <link rel="canonical" href="https://www.mrxpress.com.au/SignUp" />
      <meta name="robots" content="index,follow"></meta>
      <meta name="googlebot" content="index,follow"></meta>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Sign Up",
        "description": "Create an MR Xpress account to book and manage your mobile phone repair services.",
        "url": "https://www.mrxpress.com.au/signup"
      })}
    </Helmet>
    <Box component="main" sx={{ flexGrow: 1, mb: 5 }}>
    <HeaderInit />
    <Loader showLoader={showLoader} />  
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#EA3A13' }}>
            <LockOutlinedIcon />
          </Avatar>
          {/* <Typography component="h1" variant="h5">
            Sign up
          </Typography> */}
          <ButtonGroup aria-label="medium secondary button group">
            <Button component={Link} to="/SignUp" key="Customer" >Customer</Button>
            <GrayButton component={Link} to="/TechSignUp" key="Technician" >
              Technician
            </GrayButton>
          </ButtonGroup>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName}
                  onChange={handleChange}
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName}
                  onChange={handleChange}
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  error={errors.code ? true : false}
                  helperText={errors.code}
                  onChange={handleChange}
                  name="code"
                  required
                  fullWidth
                  id="code"
                  label="Verification Code"
                  autoFocus
                />
              </Grid>
              <Grid item xs={4} sm={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ height: '80%', fontSize:'12px'}}
                  disabled={buttonDisabled}
                  onClick={handleVerification}
                >
                  {buttonDisabled ? `Resend in ${countdown} seconds` : 'Send code'}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.phone ? true : false}
                  helperText={errors.phone}
                  onChange={handleChange}
                  required
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="mobile"
                  autoComplete="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  onChange={handleChange}
                  required={true}
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handlePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.referralCode ? true : false}
                  helperText={errors.referralCode}
                  onChange={handleChange}
                  fullWidth
                  name="referralCode"
                  label="Referral code"
                  id="referralCode"
                />
              </Grid>
              <Grid item xs={12}>
                <AddressAutoComplete />

              </Grid>
              <Grid item xs={12}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={ReCAPTCHA_SITE_KEY}
                onChange={handleCaptchaChange}
              />
              </Grid>
              <Grid item xs={12} >
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={agreeToTerms}
                        onChange={handleTermsChange}
                        name="agreeToTerms"
                        color="primary"
                        size="small" // 调整复选框大小
                        style={{ transform: 'scale(0.9)'}} // 进一步缩小复选框
                    />
                    }
                    label={
                    <Typography variant="body2" style={{ fontSize: '13px' }}>
                        I agree to the{' '}
                        <Link to="/TermsOfService" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                        terms of service
                        </Link>
                        <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    }
                    style={{ height: '10px', marginBottom: '5px' }} // 调整行间距
                />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '1px' }}>
  <FormControlLabel
    control={
      <Checkbox
        checked={agreeToSubscribe}
        onChange={handleSubscribeChange}
        name="agreeToSubscribe"
        color="primary"
        size="small" // 调整复选框大小
        style={{ transform: 'scale(0.9)',paddingTop: '0px'  }} // 进一步缩小复选框
      />
    }
    label={
      <Box display="flex" alignItems="flex-start">
        <Typography variant="body2" style={{ fontSize: '13px', lineHeight: '1.4' }}>
          I agree to subscribe to MR Xpress newsletter and receive $10 promo credit.
        </Typography>
      </Box>
    }
    style={{ alignItems: 'flex-start', height: 'auto' }} // 调整行间距
  />
</Grid>
            </Grid>

   
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Sign Up
          </Button>
        </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/SignIn" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
        <GoogleOAuthProvider clientId={config.google_auth_key}>
          <GoogleLogin
              text="Sign in with Google2"
              onSuccess={onGoogleSuccess}
              onError={onGoogleFailure}
              style={{ height: 45}}
              logo_alignment="left"
              shape="square"
          />
        </GoogleOAuthProvider>
          {/* <GoogleLogin
            onSuccess={credentialResponse => {
              console.log(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          /> */}
          <FacebookLogin
              appId={facebookAppId}
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              cssClass="my-facebook-button"
              icon={<FacebookIcon />}
          />
        </div>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
    <FooterInit />
    </Box>
    </>
  );
}