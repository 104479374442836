import React, {useEffect, useState} from "react";
import Footer from "../components/Footer";
import ContactMap from "../components/ContactMap";
import FooterInit from "../components/FooterInit";
import { Link } from "react-router-dom";
import HeaderInit from "../components/HeaderInit";
import Button from '@mui/material/Button';
import {orange} from "@mui/material/colors";
import Snackbar from '@mui/material/Snackbar';
import Api from '../utils/request/http.js';
import Loader from "../components/Loader.js";

export default function PromotionCode() {
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [promotionCode, setPromotionCode] = useState('');
    const [totalRearward, setTotalRearward] = useState(0);
    const [referralRecord, setReferralRecord] = useState([]);
    const [rewardLogs, setRewardLogs] = useState([]);
    const [showLoader, setShowLoader] = React.useState(true);

    useEffect(() => {
        // 发送请求检查用户是否有推荐码
        Api.get('/customer/me')
            .then(res => {
                setPromotionCode(res.data.referral_code);
            })
            .catch(error => {
                console.error("Error checking promotion code:", error);
            });

        // Fetch referral record
        Api.get('/customer/referralRecord')
            .then(res => {
                setReferralRecord(res.data.list);
            })
            .catch(error => {
                console.error("Error fetching referral record:", error);
            });

        Api.get('/customer/rewardLogs')
            .then(res => {
                setRewardLogs(res.data.list);
                setTotalRearward(res.data.total)
                setShowLoader(false);
            })
            .catch(error => {
                setShowLoader(false);
                console.error("Error fetching Reward logs:", error);
            });
    }, []);
    const handleCopyClick = () => {
        navigator.clipboard.writeText(promotionCode) // 要复制的文本
            .then(() => {
                setSnackbarOpen(true); // 显示 Snackbar 提示
            })
            .catch(err => {
                console.error("Failed to copy text:", err);
            });
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    
    return (
        <div style={{width: '100%',}}>
            <Loader showLoader={showLoader} />
            <HeaderInit/>
            <section className="with-bg solid-section">

                {/* <div className="fix-image-wrap" data-image-src="http://via.placeholder.com/1920x1080" data-parallax="scroll"></div> */}
                <div className="theme-back"></div>
                <div className="container page-info">

                    <div className="section-alt-head container-md">

                        <h1 className="section-title text-upper text-lg"
                            data-inview-showup="showup-translate-right">Refer a friend and get $10!</h1>

                        <p data-inview-showup="showup-translate-left">


                            <p>Introduce a friend to MR Xpress and you will get <strong><font color="orange"> $10 credit </font></strong> into your
                                account when they finish their order for 1 year of signing up. Everybody wins!</p>
                        </p>
                    </div>


                </div>

                <div className="section-footer">
                    <div className="container" data-inview-showup="showup-translate-down">
                        <ul className="page-path">


                            <li><Link to="/">Home</Link></li>

                            <li className="path-separator"><i className="fas fa-chevron-right" aria-hidden="true"></i>
                            </li>

                            <li>Refer a friend and get $10!</li>

                        </ul>
                    </div>
                </div>

            </section>
            <div className="container_main" style={{padding:'10px',marginTop:'40px'}}>

                <div className="section-head text-center container-md">

                    <h2 className="promotion_title">Here is your Referral CODE:</h2>
                    <h8>Press to Copy</h8>
                    <center>
                        <Button variant="contained" onClick={handleCopyClick} style={{width: '25%', height: '100px',backgroundColor:' #9999BB',fontSize:'xx-large'} }>{promotionCode}</Button>
                    </center>
                    <h2 className="promotion_title">Total got reward: ${totalRearward}</h2>
                </div>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    message="Code copied to clipboard!"
                />

                {/* A list to show referral record */}
                <div className="section-head text-center container-md">
                    <h3 className="promotion_title">Reward Balance Logs:</h3>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Desc</th>
                            <th scope="col">Change</th>
                            <th scope="col">Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rewardLogs.map(record => (
                            <tr key={record.id}>
                                <td>{record.remark}</td>
                                <td>{record.amount}</td>
                                <td>{record.reward_date}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div> 

                {/* A list to show referral record */}
                <div className="section-head text-center container-md">
                    <h3 className="promotion_title">Your Referral Records:</h3>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Referral Name</th>
                            <th scope="col">Referral Email</th>
                            <th scope="col">Referral Date</th>
                            <th scope="col">Reward status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {referralRecord.map(record => (
                            <tr key={record.id}>
                                <td>{record.first_name}</td>
                                <td>{record.email}</td>
                                <td>{record.referral_date}</td>
                                <td>{record.reward_status}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>    
                        
            </div>
        </div>

    )
}
