import React from "react";


export default function Loader({showLoader}) {
    console.log('showLoader:',showLoader);
    return (
        <div className="loader-block" style={showLoader ? {display: 'block'} : null}>



            <div className="loader-back alt-bg"></div>

            <div className="loader-image"><img className="image" src="/assets/images/parts/loader.gif" alt="" /></div>


        </div>
    )
}