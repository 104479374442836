import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import Item from '@mui/material/ListItem';
import Loader from '../components/Loader';
import { Box } from '@mui/material';
import {formatMobileNumber} from '../utils/functions';

export default function Review({ allData, setAllData, pageDat, setPageDat }) {
  console.log(allData)
  const { enqueueSnackbar } = useSnackbar();

  const [promoInfo, setPromoInfo] = React.useState({});

  const [repairInfo, setRepairInfo] = React.useState([]);

  const [products, setProducts] = React.useState([]);

  const [checkout, setCheckout] = React.useState({});
  console.log(checkout)

  const [promoCode, setPromoCode] = React.useState('');

  const isClickOnDiscount = React.useRef(false);

  const [balance, setBalance] = React.useState(0);

  const handleDiscount = () => {
    console.log(isClickOnDiscount.current)
    if (isClickOnDiscount.current) {
      return;
    }
    if (promoCode === '' && promoInfo) {
      isClickOnDiscount.current = true;
      fetchCheckout('').finally(() => {
        isClickOnDiscount.current = false;
      });
      return
    }
    if (promoCode === '') {
      enqueueSnackbar('Please enter a promo code',  {variant: 'error'})
      return
    }
    isClickOnDiscount.current = true;
    fetchCheckPromoCode(promoCode).finally(() => {
      isClickOnDiscount.current = false;
    });
  }

  const handlePromoCode = (event) => {
    setPromoCode(event.target.value);
    // setAllData({ ...allData, promoCode: event.target.value });
  }

  const fetchCheckPromoCode = (promoCode={}) => {
    return Api.get('/customer/checkPromoCode',{promoCode: promoCode})
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          
          fetchCheckout(promoCode)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  const fetchCheckout = (promoCode='', balance=0) => {
    // let cart = Object.keys(allData.cart).join(',')
    return Api.post('/customer/checkoutList',{...allData, promoCode: promoCode, balance: balance})
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          setAllData({ ...allData, promoCode: promoCode, balance: balance });
          setRepairInfo(data.data.repairInfo);
          setProducts(data.data.productList);
          setPromoInfo(data.data.promotion);
          setCheckout(data.data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  const handleBalanceBtn = () => {
    fetchCheckout(promoCode, balance)
  }

  const handleBalanceChange = (event) => {
    setBalance(event.target.value);
  }

  React.useEffect(() => {
    
    fetchCheckout(promoCode);
    // console.log('component', componentDat)
    

  }, []);

  return (
    <React.Fragment>
      <Loader showLoader={repairInfo.length === 0} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Repair schedule
          </Typography>
          <Typography gutterBottom><b>Full name: </b>{allData.firstName} {allData.lastName}</Typography>
          <Typography gutterBottom><b>Mobile: </b>{formatMobileNumber(allData.mobile)}</Typography>
          <Typography gutterBottom><b>Address: </b>{allData.address}</Typography>
          <Typography gutterBottom><b>Preferred time: </b>{allData.preferTime}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} sx={{ marginBottom: '16px', alignContent: 'center' }}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {repairInfo.map((repairItem) => (
                    <TableRow key={repairItem.id}>
                    <TableCell>{repairItem.phone_name} / {repairItem.repair_name}</TableCell>
                    <TableCell>x 1</TableCell>
                    <TableCell>${repairItem.price}</TableCell>
                    </TableRow>
                ))}

                {products.length > 0 && products.map((product) => (
                    <TableRow key={product.id}>
                    <TableCell>
                        <img src={product.image} alt={product.name} style={{ width: '64px', height: '64px' }} />
                        <Typography variant="body1">{product.name}</Typography>
                    </TableCell>
                    <TableCell>x {allData.cart[product.id]}</TableCell>
                    <TableCell>${product.price}</TableCell>
                    </TableRow>
                ))}

                <TableRow key='cardProcessingFee'>
                    <TableCell>Card processing fee</TableCell>
                    <TableCell></TableCell>
                    <TableCell>${checkout.paymentFee}</TableCell>
                </TableRow>

                <TableRow key='repairTotalReduced'>
                    <TableCell>
                    <Box display="flex" alignItems="center">
                        <TextField
                        label="promotion code"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        inputProps={{ min: 0, max: checkout.totalBalance }}
                        onChange={handlePromoCode}
                        sx={{width: '200px'}}
                        />
                        <IconButton color="primary" onClick={handleDiscount} sx={{ p: '10px' }} aria-label="directions">
                        <DirectionsIcon />
                        </IconButton>
                    </Box>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>- ${checkout.repairTotalReduced}</TableCell>
                </TableRow>

                <TableRow key='useBalance'>
                    <TableCell>
                    <Box display="flex" alignItems="center">
                    <TextField
                        label="Reward balance"
                        id="outlined-size-small"
                        size="small"
                        type="number"
                        inputProps={{ min: 0, max: checkout.totalBalance }}
                        onChange={handleBalanceChange}
                        sx={{width: '200px'}}
                    />
                    <IconButton color="primary" onClick={handleBalanceBtn} sx={{ p: '10px' }} aria-label="directions">
                        <DirectionsIcon />
                    </IconButton>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                    Available: ${checkout.totalBalance ?? "0.00"}
                    </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>- ${checkout.useBalance}</TableCell>
                </TableRow>

                    
                <TableRow key='finalTotal'>
                    <TableCell><b>Total</b></TableCell>
                    <TableCell></TableCell>
                    <TableCell>${checkout.finalTotal}</TableCell>
                </TableRow>

                </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      
      
      
    </React.Fragment>
  );
}
