import React from "react";

export default function Policy() {
    return (
        <>
            <div style={{ width: '100%', }}>
                <section className="with-bg solid-section">
                    <div className="theme-back"></div>
                    <div className="container page-info">
                        <div className="section-alt-head container-md">

                            <h1 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right"> PRIVACY POLICY</h1>

                            <p data-inview-showup="showup-translate-left">
                                <p >This Privacy Notice explains how we collect, store, use and protect personal data about you when you access www.mrxpress.com.au (the "Website"),  provide your personal data to us including via our social media platforms.............................</p>
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}