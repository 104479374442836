import React from "react";
import Footer from "../components/Footer";
import ContactMap from "../components/ContactMap";
import FooterInit from "../components/FooterInit";
import { Link } from "react-router-dom";
import HeaderInit from "../components/HeaderInit";
import '../styles/custom_styles.css';
import { Helmet } from "react-helmet-async";


export default function Career() {



    return (
        <>
        <Helmet>
            <title>Careers at MR Xpress | Join Our Mobile Repair Team</title>
            <meta name="description" content="Join the MR Xpress team! We're looking for skilled mobile repair technicians and customer service professionals to deliver top-quality repair services." />
            <meta name="keywords" content="Career, phone repair, MR Xpress" />
            <meta property="og:title" content="Careers at MR Xpress | Join Our Mobile Repair Team" />
            <meta property="og:description" content="Join the MR Xpress team! We're looking for skilled mobile repair technicians and customer service professionals to deliver top-quality repair services." />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="MR Xpress"></meta>
            <meta property="og:url" content="https://www.mrxpress.com.au/Career" />
            <link rel="canonical" href="https://www.mrxpress.com.au/Career" />
            <meta name="robots" content="index,follow"></meta>
            <meta name="googlebot" content="index,follow"></meta>
            {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "JobPosting",
                "title": "Mobile Phone Repair Technician",
                "hiringOrganization": {
                    "@type": "Organization",
                    "name": "MR Xpress",
                    "url": "MR Xpress "
                },
                "jobLocation": {
                    "@type": "Place",
                    "address": {
                        "@type": "PostalAddress",
                        "addressCountry": "AU",
                    }
                },
                "description": "We are hiring experienced mobile phone repair technicians to join our team. Apply now to work at MR Xpress.",
                "employmentType": "Full-Time",
                "datePosted": "2024-10-28",
                "url": "https://www.mrxpress.com.au/Career"
            })}
        </Helmet>
        <div style={{ width: '100%', }}>
            <HeaderInit />
            <section className="with-bg solid-section">
                <div className="theme-back"></div>
                <div className="container page-info">

                    <div className="section-alt-head container-md">
                        <h1 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">Career</h1>
                    </div>
                </div>
            </section>
            

            <section className="content-section">
                <div className="container">

                    <div className="section-head text-center container-md">

                        <h2 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">Do You Want to Be Your Own Boss?</h2>

                        <p data-inview-showup="showup-translate-left">Becoming a repair technician for home service repair can offer several benefits, both professionally and personally.</p>
                    </div>

                    <div className="section-alt-head container-md">
                    <img className="career-technician-img" src="assets/images/parts/carousell/technician.png" alt="Our technician" />

                    <p data-inview-showup="showup-translate-left">

                        <p> Here are some advantages of pursuing a career in this field:</p>

                        <ol className="styled-ordered-list" >
                            <li><strong>In-Demand Skills:</strong> Mobile phone repair technicians are always in demand as smartphones continually require maintenance and repair. This provides a stable job market.</li>

                            <li><strong>Independence:</strong> Many repair technicians have the option to work independently, either as freelancers or by starting their repair service businesses. This can offer greater control over your work schedule and earnings.</li>

                            <li><strong>Job Security:</strong> Home repair services are often considered essential, which can provide a level of job security even during economic downturns.</li>

                            <li><strong>Job Satisfaction:</strong> Repair technicians often find their work rewarding because they can help people solve problems and improve their quality of life by fixing essential smartphones that people cannot live without.</li>

                            <li><strong>Skills Development:</strong> This career offers opportunities for continuous learning and skill development. As technology evolves, so do repair techniques and tools.</li>

                            <li><strong>Competitive Salaries:</strong> Skilled repair technicians can earn competitive salaries, especially with experience and specialization in high-demand areas.</li>

                            <li><strong>Flexibility:</strong> Depending on your work arrangement, you may have the flexibility to set your own hours or work on a schedule that suits your lifestyle.</li>

                            <li><strong>Opportunity for Entrepreneurship:</strong> If you choose to start your own repair business, you can potentially grow it into a successful enterprise and be your boss.</li>

                            <li><strong>Environmental Impact:</strong> Repairing and maintaining items can contribute to sustainability by reducing the need for new products and minimizing waste.</li>

                            <li><strong>Job Variety:</strong> The work of a repair technician is rarely monotonous. Each day can bring new challenges and problem-solving opportunities.</li>

                            <li><strong>Community Engagement:</strong> As a home service repair technician, you can build relationships within your community and become a trusted expert in your field.</li>

                            <li><strong>Personal Fulfillment:</strong> For many, the ability to solve problems and help people is personally fulfilling and provides a sense of accomplishment.</li>

                            <li><strong>Learning Transferable Skills:</strong> The skills you develop as a repair technician, such as troubleshooting, attention to detail, and customer service, can be transferable to other fields.</li>

                            <li><strong>Job Portability:</strong> The skills you acquire as a repair technician are often portable, meaning you can apply them in various locations or even take them with you if you relocate.</li>
                        </ol>

                        <p>It's important to note that the specific benefits may vary depending on the type of repair work you specialize in and your personal career goals. Additionally, pursuing formal training, certification, and staying updated with industry developments can enhance your prospects in this field.</p>

                        <p>If you find that any of the conditions and advantages mentioned align with your preferences, why delay? Simply <Link to="/TechSignUp">click the link</Link> to become our trusted local specialist in mobile phone repair.</p>

                    </p>
                    </div>
                </div>
            </section>
        </div>
        </>
    )
}