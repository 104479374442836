import React from "react";



export default function ContactMap() {

    return (
        <section className="map-section" data-inview-showup="showup-translate-right">


        <div className="gmap" data-lat="-33.8670" data-lng="151.2045"></div>
        <div className="info-wrap">
            <div className="info-container">
                <div className="our-info side main-bg">
                    <div className="info-block">
                        <div className="info-title text-upper">Contact Us</div>
                        
                        <div className="info-line"><span className="info-icon"><i className="far fa-envelope fa-fw"
                                    aria-hidden="true"></i></span>help@mrxpress.com.au</div>
                        <div className="info-line"><span className="info-icon"></span><i class="fas fa-phone-alt"></i>  0411 93 73 72</div>
                    </div>
                    <div className="info-block">
                        <div className="info-title text-upper">Opening Hours</div>
                        <div className="info-line">Monday-Sunday<span className="pull-right">6:00 am - 11:00 pm</span></div>
                    </div>
                </div>
            </div>
        </div>

    </section>
    )
}