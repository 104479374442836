import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import {Box, Typography, Container} from '@mui/material';
import Api from '../utils/request/http.js';
import { useSnackbar } from 'notistack';
import {isStringEmpty} from '../utils/isStringEmpty.js';
import {isEmailValid} from '../utils/isEmailValid.js';
import config from '../config.js';

const ReCAPTCHA_SITE_KEY = config.recaptcha_key
export default function ForgotPassword({setShowSidebar}) {
    
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');

  const [captchaValue, setCaptchaValue] = useState(null);

  const recaptchaRef = React.useRef();

  const [errors, setErrors] = React.useState({});

  const validateForm = () => {
    let errMsg = {};
    
    errMsg.email = isStringEmpty(email) ? 'Email is required' : '';
    if (!errMsg.email) {
        errMsg.email = !isEmailValid(email) ? 'Email is not valid' : '';
    }

    setErrors({...errMsg});

    return Object.values(errMsg).every(item => item === '');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      enqueueSnackbar('Please fill in the form correctly',  {variant: 'error'})
      return;
    }

    if (captchaValue) {
      Api.post('/customer/resetPwdEmail', {"g-recaptcha-response": captchaValue, email: email})
        .then(data => {
          console.log('sent:', data);
          if (data.code !== 1000) {
            recaptchaRef.current.reset();
            enqueueSnackbar(data.msg,  {variant: 'error'})
          } else {
          enqueueSnackbar('The reset password email has been sent to your email',  {variant: 'success'})
        //   window.location.href = '/';
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
    } else {
      alert('Please finish reCAPTCHA validation。');
    }
  };

  React.useEffect(() => {
    setShowSidebar(0)
    
  }, []);

  return (
    

    <Box component="main" sx={{ flexGrow: 1,  pt: 10, pb: 13 }}>
    <Container component="main" maxWidth="xs">
        <Box
        sx={{
            // marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
        >
        
        <Typography component="h1" variant="h5">
            Forgot Password
        </Typography>
        
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              onChange={handleEmailChange}
              error={errors.email ? true : false}
              helperText={errors.email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              
            />

            
            <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={ReCAPTCHA_SITE_KEY}
            onChange={handleCaptchaChange}
            />
            <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '16px' }}
            >
            Submit
            </Button>
        </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
    </Box>
  );
}


