const config = {
    development: {
        api: 'http://api.mrxpress.local/api',
        tech_host: 'http://technician.mrxpress.local',
        map_key:'AIzaSyDph5geXd_i2jYfK3nXifbDylc_vhMaCLU',
        google_auth_key: '601532316221-q7eaococg6qhrphl5mj3u0lpp6unq1e4.apps.googleusercontent.com',
        facebook_app_id: '1090277846100373',
        recaptcha_key: '6LdgJYspAAAAACC3N_gzsbb-RoQy2iM5yvhoDQFg',
        pusher_app_key: "6c1f08f77f9a5cab841f",
        env: 'DEV',
    },
    test: {
        api: 'https://api-dev.mrxpress.com.au/api',
        tech_host: 'https://technician-dev.mrxpress.com.au',
        map_key:'AIzaSyDph5geXd_i2jYfK3nXifbDylc_vhMaCLU',
        google_auth_key: '601532316221-q7eaococg6qhrphl5mj3u0lpp6unq1e4.apps.googleusercontent.com',
        facebook_app_id: '1090277846100373',
        recaptcha_key: '6LdgJYspAAAAACC3N_gzsbb-RoQy2iM5yvhoDQFg',
        pusher_app_key: "6c1f08f77f9a5cab841f",
        env: 'TEST',
    },
    production: {
        api: 'https://api.mrxpress.com.au/api',
        tech_host: 'https://technician.mrxpress.com.au',
        map_key:'AIzaSyDph5geXd_i2jYfK3nXifbDylc_vhMaCLU',
        google_auth_key: '601532316221-q7eaococg6qhrphl5mj3u0lpp6unq1e4.apps.googleusercontent.com',
        facebook_app_id: '1090277846100373',
        recaptcha_key: '6Ld2l4wpAAAAAMb5-AWJsXzZwvFv83zUe431al4c',
        pusher_app_key: "6c1f08f77f9a5cab841f",
        env: 'PROD',
    }
}

const env = process.env.REACT_APP_ENV || 'development';

export default config[env]