import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import AddressAutoComplete from '../components/AddressAutoComplete';
import Loader from '../components/Loader';
import { format } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isAfter, setHours, setMinutes, addDays } from 'date-fns';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';


export default function AddressForm({ allData, setAllData, pageDat, setPageDat }) {

  const [showLoader, setShowLoader] = React.useState(true);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [selectedDate, setSelectedDate] = React.useState(null);

  const [formData, setFormData] = React.useState({});
  console.log('formData:' ,formData)

  const handleFirstNameChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, ...{firstName: value} });
    setAllData({ ...allData, ...{firstName: value} });
  }

  const handleLastNameChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, ...{lastName: value} });
    setAllData({ ...allData, ...{lastName: value} });
  }

  const handleMobileChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, ...{mobile: value} });
    setAllData({ ...allData, ...{mobile: value} });
  }

  const isValidTime = (date) => {
    const hour = date.getHours();
    return hour >= 9 && hour < 18;
  };

  const shouldDisableTime = (timeValue, clockType) => {
    if (clockType === 'hours') {
      return timeValue < 9 || timeValue >= 18;
    }
    return false;
  };

  const now = new Date();
  const sixPM = setMinutes(setHours(new Date(), 18), 0);
  const minDate = isAfter(now, sixPM) ? setMinutes(setHours(addDays(new Date(), 1), 8), 0) : new Date();
  const isTimeInValidRange = (date) => {
    return isValidTime(date) && date >= now;
  };

  const handleDateTimeChange = (newValue) => {
    if (isTimeInValidRange(newValue)) {
      newValue = format(newValue, "yyyy-MM-dd HH:mm:ss");
      setSelectedDate(newValue);
      setFormData({ ...formData, ...{ preferTime: newValue.toString() } });
      setAllData({ ...allData, preferTime: newValue.toString() });
    } else {
      setSelectedDate(null);
      setFormData({ ...formData, preferTime: null });
      setAllData({ ...allData, preferTime: null });

    }

  };

  const fetchUser = () => {
    if (allData.address && allData.firstName && allData.lastName) {
      const userAddress = {
        firstName: allData.firstName, 
        lastName: allData.lastName, 
        address: allData.address, 
        mobile: allData.mobile, 
        preferTime: allData.preferTime
      };
      setFormData({ ...formData, ...userAddress });
      setShowLoader(false);
      return;
    }
    const userDetail = allData.user;
    if (userDetail) {
      setFormData({ ...formData, ...userDetail });
      const userAddress = {
        firstName: userDetail.firstName, 
        lastName: userDetail.lastName, 
        address: userDetail.address, 
        mobile: userDetail.mobile
      };
      setAllData({ ...allData, ...userAddress });
    }
    
    setShowLoader(false);
  };

  const handleAddressCallback = (childData) => {
    console.log('address call back', childData)
    if (childData) {
      setFormData({ ...formData, ...{address: childData.description} });
      setAllData({ ...allData, ...{address: childData.description} });
    }
    
}

  React.useEffect(() => {
    fetchUser();
    
    
  }, []);

  return (
    <React.Fragment>
      <Loader showLoader={showLoader} />
      <Typography variant="h6" gutterBottom>
        Repair schedule
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            autoFocus
            onChange={handleFirstNameChange}
            value={formData.firstName}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            onChange={handleLastNameChange}
            value={formData.lastName}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Address line "
            fullWidth
            autoComplete="shipping address-line"
            variant="standard"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            value={formData.address}
          />
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="mobile"
            name="mobile"
            label="Preferrd contact number"
            fullWidth
            variant="standard"
            onChange={handleMobileChange}
            value={formData.mobile}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <AddressAutoComplete 
            defaultAddr={formData.address} 
            handleCallback={handleAddressCallback} 
            inputVariant='standard'
            />
        </Grid>
        
        {/* add a Datetime picker*/}
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label="Preferred time"
                value={selectedDate}
                onChange={(newValue) => {
                  if (isTimeInValidRange(newValue)) {
                    handleDateTimeChange(newValue);
                  } else if (!newValue || newValue < now) {
                     // Reset if invalid
                  }
                }}
                minDateTime={minDate}
                shouldDisableTime={shouldDisableTime}
                renderInput={(params) => <TextField {...params} fullWidth variant="standard" />}
                ampm={false}
                disablePast
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Typography variant="body2" gutterBottom style={{ marginTop: '10px', marginBottom: '0px' }}>
        Note:
      </Typography>
      <List dense style={{paddingTop: '3px', paddingBottom: '0px'}}>
        <ListItem style={{paddingTop: '3px', paddingBottom: '0px'}}>
          <ListItemIcon style={{ minWidth: '10px' }}>
            <FiberManualRecord fontSize="small" style={{ color: 'black', fontSize: '0.5rem' }} />
          </ListItemIcon>
          <ListItemText primary="We currently provide services for the greater Sydney areas only." />
        </ListItem>
        <ListItem>
          <ListItemIcon style={{ minWidth: '10px' }}>
            <FiberManualRecord fontSize="small" style={{ color: 'black', fontSize: '0.5rem' }} />
          </ListItemIcon>
          <ListItemText primary="Preferred time can only be between 9am and 6pm." />
        </ListItem>
      </List>
    </React.Fragment>
  );
}
