import React, { useRef, useImperativeHandle } from 'react'



const StripeInput = React.forwardRef((props, ref) => {
    const { component: Component, ...other } = props;
    const elementRef = useRef();
    // implement `InputElement` interface
    React.useImperativeHandle(ref, () => ({
        focus: () => elementRef.current.focus,
        // hiding the value e.g. react-stripe-elements
    }));
    
    // `Component` will be your `SomeThirdPartyComponent` from below
    return <Component onReady={element => (elementRef.current = element)} {...other} />;
    });


export default StripeInput