import Footer from "./Footer";
import Navbar from "./Navbar";
import Cookie from './Cookie';
import { Outlet } from "react-router-dom";

export default function Layout({isIndex}) {
  
    return (
      <>
        <Navbar isIndex={isIndex} />
        <Outlet />
        <Cookie />
        <Footer isIndex={isIndex} />
      </>
    );
  }