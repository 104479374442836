import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import config from '../config';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';

export default function DevAccessValidation() {
  const [showLoader, setShowLoader] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  // const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const curEnv = config.env

  const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    
    setOpen(true);
  };

  React.useEffect(() => {
    const dev_verify_status = localStorage.getItem('dev_verify_status');
    console.log(dev_verify_status)
    if (curEnv != 'PROD' && dev_verify_status != 1) {
        setOpen(true)
    }

  }, [])

  return (


    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            setShowLoader(true)
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            console.log(formJson.dev_pwd);
            if (!formJson.dev_pwd) {
                enqueueSnackbar('Please enter password',  {variant: 'error'})
            }
            Api.post('/customer/verifyDevPwd', {dev_pwd: formJson.dev_pwd})
                .then(res => {
                    setShowLoader(false)
                    if (res.code !== 1000) {
                        enqueueSnackbar(res.msg,  {variant: 'error'})
                        return;
                    }
                    // setOpen(false)
                    localStorage.setItem('dev_verify_status', 1)
                    enqueueSnackbar('Success',  {variant: 'success'})
                    window.location.reload()
                })
                .catch(e => {
                    setShowLoader(false)
                    enqueueSnackbar('Network error',  {variant: 'error'})
                })
          },
          
        }}
        BackdropProps={{
          style: { backgroundColor: '#fff' },
        }}
      >
        <DialogTitle>Access Validation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the password to access the Dev Env
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="dev_pwd"
            name="dev_pwd"
            label="Access Password"
            type="password"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          {/* <Button type="submit">Verify</Button> */}
          <LoadingButton loading={showLoader} variant="outlined" type="submit">
            Verify
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
