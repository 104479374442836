import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import PhoneRepair from './PhoneRepair';
import Accessories from './Accessories';
import { MobileStepper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import { useNavigate, useBlocker } from 'react-router-dom';
import {
  useStripe,
  useElements,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import Loader from '../components/Loader';
import Cookies from 'js-cookie';
import { isPhoneValid } from '../utils/isPhoneValid';
// import LeavePageTip from '../components/LeavePageTip';
import { trackEvent, trackPageview } from '../components/Gtag';


const steps = [
    'Repair Information', 
    'Select accessories', 
    'Repair schedule', 
    'Review order', 
    'Payment details', 
];



function getStepContent(step, allData, setAllData, componentDat, setCompnentDat) {
  trackPageview(`/repairForm/step${step+1}`);
  switch (step) {
    case 0:
      return <PhoneRepair 
        allData={allData} 
        setAllData={setAllData} 
        pageDat={componentDat} 
        setPageDat={setCompnentDat}
      />;
    case 1:
      return <Accessories allData={allData} 
        setAllData={setAllData} 
        pageDat={componentDat} 
        setPageDat={setCompnentDat}
        />;
    case 2:
      return <AddressForm allData={allData} 
        setAllData={setAllData} 
        pageDat={componentDat} 
        setPageDat={setCompnentDat}
        />;
    case 3:
      return <Review allData={allData} 
        setAllData={setAllData} 
        pageDat={componentDat} 
        setPageDat={setCompnentDat}
        />;
    case 4:
      return <PaymentForm allData={allData} 
      setAllData={setAllData} 
      pageDat={componentDat} 
      setPageDat={setCompnentDat}
      />;

    default:
      throw new Error('Unknown step');
  }
}

export default function Repair() {

  const [showLoader, setShowLoader] = React.useState(false);
  
  const isMobile = useMediaQuery('(max-width: 600px)');

  const [activeStep, setActiveStep] = React.useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const [componentDat, setCompnentDat] = React.useState(0);
  console.log('componentDat:',componentDat);


  const [allData, setAllData] = React.useState({cart: {}, repairs: [], imgs: [], imgPath:[]});
  console.log('alldata:',allData);

  const [orderInfo, setOrderInfo] = React.useState({});

  const [userDetail, setUserDetail] = React.useState({});
  
  const theme = useTheme();

  const maxSteps = steps.length;

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const checkValid = (step) => {
    switch (step) {
      case 0:
        if (allData.repairs.length > 0 &&
          allData.repairs[0].phoneType &&
          allData.repairs[0].repairType) {
          return true;
        } else {
          enqueueSnackbar('Please sleect at least one phone type and repair type', {variant: 'error'})
          return false;
        }
      case 1:
        return true;
      case 2:
        if (allData.firstName && allData.lastName && allData.mobile && allData.address) {
          if (!isPhoneValid(allData.mobile)) {
            enqueueSnackbar('Mobile number is not valid', {variant: 'error'})
            return false;
          }
          return true;
        } else {
          enqueueSnackbar('Please fill in first name, last name, mobile and address', {variant: 'error'})
          return false;
        }
      case 3:
        return true;
      case 4:
        if (allData.user.strpePMId) {
          return true;
        }
        if (allData.cardName) {
          return true;
        } else {
          enqueueSnackbar('Please fill in card name, card number, expire date and cvv', {variant: 'error'})
          return false;
        }
      default:
        return false;
    }
  }
  
  const handleNext = () => {
    if (checkValid(activeStep)) {
      setActiveStep(activeStep + 1);
    }
    
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const updateOrderPaid = async (orderId) => {
    Api.post('/customer/updateOrderPaid', {orderId: orderId})
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        }
      }
      )
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  }

  const createHoldOnPayment =  (paymentMethodId, orderId) => {
    
    Api.post('/payment/paymentIntents', {paymentMethodId: paymentMethodId, orderId: orderId})
      .then(async (data) => {
        setShowLoader(false)
        console.log('data:',data);
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          if (!data.data.requires_action) {
            enqueueSnackbar('Order placed successfully',  {variant: 'success'})
            setActiveStep(activeStep + 1);
            updateOrderPaid(orderId)
          }
          
          if (data.data.requires_action) {
            const stripSecret = data.data.client_secret;
            stripe.confirmCardPayment(stripSecret)
              .then(function(result) {
                // Handle result.error or result.paymentIntent
                if (result.error) {
                  enqueueSnackbar(result.error.message,  {variant: 'error'})
                  return;
                }
                enqueueSnackbar('Order placed successfully',  {variant: 'success'})
                setActiveStep(activeStep + 1);
                updateOrderPaid(orderId)
              });
          }

          if (allData.saveCard) {
            console.log('save card')
            handleSaveCard(paymentMethodId)
          }
        }
      })
      .catch((error) => {
        setShowLoader(false)
        console.log('error:',error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
    }

  const handleSaveCard = async (paymentMethodId) => {
    Api.post('/payment/savePaymentMethod', {pmId: paymentMethodId})
      .then((data) => {
        console.log('data:',data);
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } 
      }
      )
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      }); 
  }
  
  const createPaymentMethod = async (orderId) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      enqueueSnackbar('Stripe hasn not yet loaded',  {variant: 'error'})
      setShowLoader(false)
      return;
    }
    if (userDetail.strpePMId) {
      createHoldOnPayment(userDetail.strpePMId, orderId)
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardCvcElement),
      billing_details: {
        name: allData.cardName,
      },
    });

    if (result.error) {
      enqueueSnackbar(result.error.message, {variant: 'error'});
      setShowLoader(false)
    } else {
      console.log(result.paymentMethod.id);
      createHoldOnPayment(result.paymentMethod.id, orderId)
    }

    if (!allData.saveCard) {
      handleRemoveCard(result.paymentMethod.id)
    }
  };

  const handleRemoveCard = async (paymentMethodId) => {
    Api.post('/payment/removePaymentMethod', {pmId: paymentMethodId})
      .then((data) => {
        // console.log('data:',data);
        // if (data.code !== 1000) {
        //   enqueueSnackbar(data.msg,  {variant: 'error'})
        // }
      }
      )
      .catch((error) => {
        console.error('Error:', error);
        // enqueueSnackbar('Remove Network error',  {variant: 'error'})
      });
  }

  const handlePlaceOrder = () => {
    if (!checkValid(activeStep)) {
      return
    }

    const tempUser = allData.user;
    const tempIms = allData.imgs;
    // delete allData.user;
    delete allData.imgs
    setShowLoader(true)
    Api.post('/customer/placeOrder', allData)
    .then((data) => {
      console.log('data:',data);
      if (data.code !== 1000) {
        allData.user = tempUser
        allData.imgs = tempIms
        enqueueSnackbar(data.msg,  {variant: 'error'})
        setShowLoader(false)
      } else {
        // enqueueSnackbar('Order placed successfully',  {variant: 'success'})
        // window.location.href = data.data.payUrl;
        // let orderId = data.data.orderId;
        setOrderInfo(data.data)
        createPaymentMethod(data.data.orderId)
        // handleClientSecret(orderId)
      }
    })
    .catch((error) => {
      setShowLoader(false)
      allData.user = tempUser
      allData.imgs = tempIms
      console.log('error:',error);
      enqueueSnackbar('Network error',  {variant: 'error'})
    });
  }

  const fetchUser = async () => {
    Api.get('/customer/me', {})
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
          return
        }
        const userDetail = {
          firstName: data.data.first_name, 
          lastName: data.data.last_name, 
          address: data.data.address,
          email: data.data.email,
          mobile: data.data.mobile,
          strpeCustomerId: data.data.stripe_customer_id,
          strpePMId: data.data.stripe_pm_id,
        }
        setAllData({...allData, user: userDetail});
        setUserDetail(userDetail)
      }
      )
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  }
  
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    const message = 'Are you sure to leave the page? all of the repair booking selections might be lost.';
    event.returnValue = message; // Gecko + IE
    return message; // Gecko + Webkit, Safari, Chrome等
  };

  const userToken = Cookies.get('acc_token')
  useEffect(() => {

    let oldData = JSON.parse(localStorage.getItem('repair_alldata'));
    if (oldData) {
      setAllData(oldData);
    }

    if (userToken) {
      fetchUser();
    }

    let oldStep = JSON.parse(localStorage.getItem('repair_step'));
    if (oldStep) {
      setActiveStep(oldStep);
      if (userToken) {
        localStorage.removeItem('repair_step');
        localStorage.removeItem('repair_alldata');
      }
    }

    

    // window.addEventListener('beforeunload', handleBeforeUnload);

    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
    
  }, [])
  console.log(maxSteps, activeStep)
  return (
    <>
      <CssBaseline />
      <Loader showLoader={showLoader} />
      <Container component="main"  sx={{ mb: 4, minWidth: activeStep === 1 ? '100%' : '60%' }}>
       {isMobile ? (
        // 移动设备上显示 MobileStepper
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
        {/* <Paper
            square
            elevation={0}
            sx={{
            display: 'flex',
            alignItems: 'center',
            height: 50,
            pl: 2,
            bgcolor: 'background.default',
            }}
        >
            <Typography>{steps[activeStep]}</Typography>
        </Paper> */}
        

        {activeStep === maxSteps ? (
                    <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                        Thank you for your order.
                    </Typography>
                    <Typography variant="subtitle1">
                        Your order number is #{orderInfo.orderNo}.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/OrderLst')}
                        sx={{ mt: 3, ml: 1 }}
                        >
                        View order
                    </Button>
                    </React.Fragment>
                ) : (
                  <>
                  <Box sx={{ width: '100%', p: 2 }}>
                      {getStepContent(activeStep, allData, setAllData, componentDat, setCompnentDat)}
                  </Box>
                  <MobileStepper
                  variant="text"
                  steps={maxSteps}
                  // position="static"
                  activeStep={activeStep}
                  nextButton={
                  <Button
                      size="small"
                      onClick={activeStep === maxSteps - 1 ? handlePlaceOrder : handleNext}
                      // disabled={activeStep === maxSteps - 1}
                  >
                      {activeStep === maxSteps - 1 ? 'Place order' : 'Next'} 
      
                      {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                      ) : (
                      <KeyboardArrowRight />
                      )}
                  </Button>
                  }
                  backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                      {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                      ) : (
                      <KeyboardArrowLeft />
                      )}
                      Back
                  </Button>
                  }
              />
              </>
                )}

        
        </Box>
      ) : (
        
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
                    Repair service
                </Typography>
                <Stepper activeStep={activeStep} sx={{  pb: 5 }}>
                    {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                        Thank you for your order.
                    </Typography>
                    <Typography variant="subtitle1">
                        Your order number is #{orderInfo.orderNo}.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/OrderLst')}
                        sx={{ mt: 3, ml: 1 }}
                        >
                        View order
                    </Button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                    {getStepContent(activeStep, allData, setAllData, componentDat, setCompnentDat)}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {activeStep !== 0 && (
                        <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                            Back
                        </Button>
                        )}
                        {activeStep === steps.length - 1 ? (
                          <Button
                          variant="contained"
                          onClick={handlePlaceOrder}
                          sx={{ mt: 3, ml: 1 }}
                          >
                          Place order
                          </Button>
                        ) : (
                          <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 3, ml: 1 }}
                          >
                          Next
                          </Button>
                        )}
                        
                        {activeStep === 1 ? (
                            <Button onClick={handleNext} variant="contained" sx={{ mt: 3, ml: 1 }}>
                                skip
                            </Button>
                        ) : null}
                    </Box>
                    </React.Fragment>
                )}
        </Paper>
      )}
      {/* <LeavePageTip /> */}
      </Container>
      </>
  );
}
