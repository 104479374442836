import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useMediaQuery } from '@mui/material';
import { Link, Route, Switch } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import jsCookie from 'js-cookie';

const drawerWidth = 240;

export default function SideBar({selectedIndex}) {

  // const [selectedIndex, setSelectedIndex] = React.useState('User')
  console.log(selectedIndex)

  const isMobile = useMediaQuery('(max-width: 600px)');

  const [mobileOpen, setMobileOpen] = React.useState(isMobile)

  const { enqueueSnackbar } = useSnackbar();

  const container = window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSignOut = () => {
    Api.post('/customer/logout')
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
          return
        }
        enqueueSnackbar(data.msg, {variant: 'success'})
        jsCookie.remove('acc_token')
        window.location.href = '/'
      }
      )
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  const barList = [
    {id: 1, name: 'User Detail', path: '/UserHome'}, 
    {id: 2, name: 'Order List', path: '/OrderLst'}
  ]

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {barList.map((item, index) => (
          <ListItem key={item.id} disablePadding sx={{ textDecoration: 'none', color: 'inherit' }} component={Link} to={`${item.path}`}>
            <ListItemButton selected={selectedIndex === item.id}>
              <ListItemIcon>
                {index % 2 === 0 ? <AccountCircleIcon /> : <FormatListBulletedIcon />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem key='logout' disablePadding sx={{ textDecoration: 'none', color: 'inherit' }} onClick={handleSignOut}>
          <ListItemButton >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary='Sign out' />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
  );
}