import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Loader from '../components/Loader';
import Api from '../utils/request/http';
import { Avatar, Chip, Rating, Typography } from '@mui/material';
import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';

export default function TechnicianIntro() {
  const [showLoader, setShowLoader] = useState(true);
  const [technician, setTechnician] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  

  const fetchTechnician = () => {
    Api.get('/customer/technicianProfile?tech_id=' + params.technicianId)
      .then((response) => { 
        setTechnician(response.data);
        console.log(response.data.technician.avg_rating);
        setShowLoader(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setShowLoader(false);
      });
  };

  useEffect(() => {
    fetchTechnician();
  }, [params.technicianId]);

  return (
    <Container>
      <Loader showLoader={showLoader} />  
      <Toolbar />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Technician Profile</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Avatar
                      src={technician.technician?.avatar}
                      alt={technician.technician?.first_name}
                      sx={{ width: 100, height: 100 }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{technician.technician?.first_name}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <Box display="flex" alignItems="center">
                        <Typography>{parseFloat(technician.technician?.avg_rating)}</Typography>
                        <Rating
                          name="tech_rating"
                          value={parseFloat(technician.technician?.avg_rating)}
                          precision={0.1}
                          readOnly
                          sx={{ ml: 1 }}
                        />
                        <Typography sx={{ ml: 1 }}>({parseFloat(technician.technician?.total_feedbacks)})</Typography>
                      </Box>
                  </Grid>
                  
                    {/* Technician skills tags */}
                    <Grid item xs={12} sx={{mt:1}}>
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {technician.technician && technician.technician.skills && technician.technician.skills.map((tag, index) => (
                          <Chip 
                            key={index} 
                            label={tag} 
                            sx={{
                              borderRadius: '24px',
                              border: '1px solid #ccc',
                              padding: '0 10px',
                              height: '30px',
                            }} 
                          />
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sx={{mt:2}}>
                      <Typography variant="body1">
                        {technician.technician?.skill_desc}
                      </Typography>
                    </Grid>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Typography variant="h6">Feedbacks</Typography>
                  </Grid>
                  {/* list all feedback */}
                  {technician.feedbacks &&
                    technician.feedbacks.map((feedback) => (
                      <Grid item xs={12} key={feedback.id}>
                        <Paper>
                          <Box p={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} display="flex" alignItems="center">
                              <Typography variant="subtitle1">{feedback.cs_name}</Typography>
                              <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }}>{feedback.cs_email}</Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={4} textAlign="right">
                              <Typography variant="body2" color="textSecondary">{feedback.cs_time_desc}</Typography>
                            </Grid> */}
                            <Grid item xs={12} display="flex" alignItems="center">
                              <Rating
                                value={feedback.cs_rating}
                                precision={0.1}
                                readOnly
                              />
                              <Typography variant="body2" sx={{ ml: 1 }}>{feedback.cs_time_desc}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "10px" }}>
                              <Typography variant="body1">{feedback.cs_feedback}</Typography>
                            </Grid>
                          </Grid>
                            
                          </Box>
                        </Paper>
                      </Grid>
                    ))}

                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Loader open={showLoader} />
    </Container>
  );
}