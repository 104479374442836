import React, { useState } from 'react';
import {
  Button,
  Box,
  Container,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


export default function OrderRating() {

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const params = useParams();

    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState('');

    const handleRatingChange = (event, newRating) => {
        setRating(newRating);
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const submitFeedback = () => {
        Api.post('/customer/orderRating', {rating: rating, feedback: feedback, orderId: params.orderId})
            .then((data) => {
                console.log('User created:', data);
                if (data.code !== 1000) {
                    enqueueSnackbar(data.msg,  {variant: 'error'})
                } else {
                    enqueueSnackbar('Feedback saved successfully',  {variant: 'success'})
                    navigate('/OrderLst')
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Network error',  {variant: 'error'})
            });
    };

    React.useEffect(() => {
        // setShowSidebar(1)
        
    }, [])

    return (
        <Container component="main" maxWidth="lg" >
        <Box component="form" noValidate sx={{ flexGrow: 1,  }}>
            <Toolbar />
            <div>
            <Typography variant="h6" gutterBottom >
                Order Feedback
            </Typography>
                <div>
                    <p>Rate your order:</p>
                    <Rating
                    name="order-rating"
                    value={rating}
                    onChange={handleRatingChange}
                    />
                </div>
                <div>
                    <p>Feedback:</p>
                    <TextField
                    label="Feedback"
                    multiline
                    rows={5}
                    value={feedback}
                    onChange={handleFeedbackChange}
                    sx={{ width: '50ch' }}
                    />
                </div>
                <Button  sx={{ mt: 3, mr: 1 }} variant="contained" color="primary" onClick={() => {navigate(-1);}}>
                    Back
                </Button>
                <Button variant="contained" color="primary" onClick={submitFeedback} sx={{ mt: 3, ml: 1 }}>
                    Submit
                </Button>
                
            </div>
        </Box>
        </Container>
    )

}