import React from "react";
import Footer from "../components/Footer";
import ContactMap from "../components/ContactMap";
import FooterInit from "../components/FooterInit";
import { Link } from "react-router-dom";
import HeaderInit from "../components/HeaderInit";
import { Helmet } from 'react-helmet-async';


export default function AboutUs() {
    return (
        <>
        <Helmet>
            <title>About MR Xpress | Your Trusted Mobile Phone Repair Service</title>
            <meta name="description" content="Learn about MR Xpress, Australia’s trusted mobile phone repair service provider. We bring expert repair services directly to your doorstep." />
            <meta name="keywords" content="About us, phone repair, MR Xpress" />
            <meta property="og:title" content="About MR Xpress | Your Trusted Mobile Phone Repair Service" />
            <meta property="og:description" content="Learn about MR Xpress, Australia’s trusted mobile phone repair service provider. We bring expert repair services directly to your doorstep." />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="MR Xpress"></meta>
            <meta property="og:url" content="https://www.mrxpress.com.au/AboutUs" />
            <link rel="canonical" href="https://www.mrxpress.com.au/AboutUs" />
            <meta name="robots" content="index,follow"></meta>
            <meta name="googlebot" content="index,follow"></meta>
            {JSON.stringify({
                "@context": "https://schema.org",
                "name": "About Us",
                "description": "Learn about MR Xpress, Australia’s trusted mobile phone repair service provider. We bring expert repair services directly to your doorstep.",
                "url": "https://www.mrxpress.com.au/AboutUs",
                "@type": "Organization",
                "logo": "https://www.mrxpress.com.au/logo.png",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Sydney streets",
                    "addressLocality": "Sydney",
                    "addressRegion": "NSW",
                    "postalCode": "2000",
                    "addressCountry": "AU"
                },
                "sameAs": [
                    "https://www.facebook.com/MobileRepairXpress/", 
                    "https://www.instagram.com/mrxpress.admin/",
                    "https://www.youtube.com/@MrXpress_Services"
                ]
            })}
        </Helmet>
        <div style={{ width: '100%', }}>
            <HeaderInit />
            <section className="with-bg solid-section">

                {/* <div className="fix-image-wrap" data-image-src="http://via.placeholder.com/1920x1080" data-parallax="scroll"></div> */}
                <div className="theme-back"></div>
                <div className="container page-info">

                    <div className="section-alt-head container-md">

                        <h1 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">About Us</h1>


                    </div>
                </div>

            </section>

            <section className="content-section">
                <div className="container">

                    <div className="section-head container-md">
                        <p data-inview-showup="showup-translate-left">

                            <p>MR Xpress is a revolutionary platform that facilitates the connection between customers in need of mobile phone repair services and qualified technicians who can provide these services in the comfort of the customer's home. In today's fast-paced world, where mobile phones are essential for communication and work, our platform offers convenience and efficiency like never before. This business model caters to both customers seeking reliable repair services and skilled technicians looking to expand their reach and grow their businesses.</p>
                            <br/>
                            <strong>Vision Statement</strong>
                            <p>To create a seamless and nationwide network that brings mobile phone repair services right to the customer's doorstep, ensuring a hassle-free and efficient experience for all.</p>
                        </p>
                    </div>
                </div>
            </section>

        </div>
        </>
    )
}