import React from "react";
import config from "../config";

const GOOGLE_MAPS_API_KEY = config.map_key;
export default function FooterInit(){
    React.useEffect(() => {
        const removeList = []
        const scriptList = [
          'assets/js/script.js',
          'https://maps.googleapis.com/maps/api/js?key='+ GOOGLE_MAPS_API_KEY +'&callback=initMap&libraries=places',
          
        ];
        scriptList.forEach((item) => {
          const script = document.createElement('script');
          script.src = item;
          script.async = false;  
          document.body.appendChild(script);
          removeList.push(script);
        });
        return () => {
          removeList.forEach((item) => {
            document.body.removeChild(item);
          });
        };
        
    }, []);
}