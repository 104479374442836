import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CloseIcon from '@mui/icons-material/Close';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../config';
import { useSnackbar } from 'notistack';
import Api from '../utils/request/http.js';
import { LoadingButton } from '@mui/lab';


const ReCAPTCHA_SITE_KEY = config.recaptcha_key

const FeedbackButton = () => {
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [captchaValue, setCaptchaValue] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const formRef = React.useRef({});
    const recaptchaRef = React.useRef();
    const [showLoader, setShowLoader] = React.useState(false);

    const handleWantToDoChange = (event) => {
        const { value } = event.target;
        formRef.current.wantToDo = value;
    };

    const handleEmailChange = (event) => {
        const { value } = event.target;
        formRef.current.email = value;
    };

    const handleAbleToDoChange = (event) => {
        const { value } = event.target;
        formRef.current.ableToDo = value;
    };
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleImageChange = (event) => {
      const files = Array.from(event.target.files);
      const maxFileSize = 8 * 1024 * 1024; // 8MB

      for (let file of files) {
        if (file.size > maxFileSize) {
          alert('Each image must be smaller than 8MB.');
          return;
        }
      }

      if (files.length + images.length > 3) {
        alert('You can only upload up to 3 images.');
        return;
      }
      const newImages = files.map(file => ({
        file,
        url: URL.createObjectURL(file)
    }));
      setImages(prevImages => [...prevImages, ...newImages]);
    };

    const handleRemoveImage = (index) => {
      setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const handleCaptchaChange = (value) => {
      setCaptchaValue(value);
    };

    const uploadImages = async () => {
        const uploadPromises = images.map((image, index) => {
            const formData = new FormData();
            formData.append('image', image.file);
    
            return Api.post('/customer/imageUpload', formData, {'Content-Type': 'multipart/form-data'})
                .then(res => {
                    console.log(`Image ${index} uploaded successfully:`, res);
                    return res.data.path;
                })
                .catch(err => {
                    console.error(`Failed to upload image ${index}:`, err);
                    throw err;
                });
        });
    
        try {
            const results = await Promise.all(uploadPromises);
            console.log('All images uploaded successfully:', results);
            return results
        } catch (err) {
            console.error('Failed to upload one or more images:', err);
        }
        return []
    };

    const handleFeedbackSubmit = async (event) => {
        event.preventDefault();
        setShowLoader(true)

        if (!captchaValue) {
            enqueueSnackbar('Please complete the reCAPTCHA',  {variant: 'error'})
            return;
        }
        // 先上传所有图片到服务器
        const image_url_list = await uploadImages();
        // 提交反馈
        const feedbackData = {
          email: formRef.current.email, 
          images: image_url_list, 
          wantToDo: formRef.current.wantToDo, 
          ableToDo: formRef.current.ableToDo, 
          "g-recaptcha-response": captchaValue
        }
        Api.post('/customer/feedback', feedbackData)
            .then((data) => {
                setImages([]);
                setShowLoader(false)
                if (data.code !== 1000) {
                    enqueueSnackbar(data.msg,  {variant: 'error'})
                    return
                }
                enqueueSnackbar('Feedback submitted successfully!', {variant: 'success'});
                setOpen(false);
            })
            .catch((error) => {
                setImages([]);
                setShowLoader(false)
                setOpen(false);
                console.error('Error:', error);
                enqueueSnackbar('Network error',  {variant: 'error'})
            });

        
    };


  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        style={{
            position: 'fixed',
            right: '0',
            top: '70%',
            transform: 'translateY(-50%) rotate(180deg)',
            backgroundColor: '#29abe2',
            color: 'white',
            border: '1px solid #29abe2',
            fontSize: '14px',
            fontWeight: '500',
            cursor: 'pointer',
            borderRadius: '4px',
            writingMode: 'vertical-rl',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '40px',
            height: '120px',
            padding: '0',
            boxShadow: 'none',
            zIndex: '20',
            textTransform: 'none'
        }}
        >
        Feedback
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Contact email"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleEmailChange}
          />
          <TextField
            margin="dense"
            label="What did you want to do today?"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleWantToDoChange}
          />
          <TextField
            margin="dense"
            label="Were you able to do it?"
            type="email"
            fullWidth
            variant="outlined"
            onChange={handleAbleToDoChange}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-photo"
            type="file"
            multiple
            onChange={handleImageChange}
          />
          <label htmlFor="upload-photo">
            <IconButton color="primary" component="span">
              <PhotoCamera />
            </IconButton>
            Upload Photo
          </label>
          <div style={{ display: 'flex', marginTop: '10px' }}>
            {images.map((image, index) => (
              <div key={index} style={{ position: 'relative', marginRight: '10px' }}>
                <img src={image.url} alt={`upload-${index}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                <IconButton
                  size="small"
                  style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
                  onClick={() => handleRemoveImage(index)}
                >
                  <CloseIcon fontSize="small" /> {/* 使用 CloseIcon */}
                </IconButton>
              </div>
            ))}
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={ReCAPTCHA_SITE_KEY}
            onChange={handleCaptchaChange}
            style={{ marginTop: '10px' }}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          
          <LoadingButton loading={showLoader} onClick={handleFeedbackSubmit}>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeedbackButton;