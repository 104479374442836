import React, { useState } from 'react';
import {
  Button,
  Box,
  Container,
  Avatar,
  Tooltip,
  Rating,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Api from '../utils/request/http';
import Pagination from '@mui/material/Pagination';
import ResponsiveDialog from '../components/ResponsiveDialog';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';


function OrderLst({setShowSidebar, setSelectedIndex}) {

  const [showLoader, setShowLoader] = React.useState(true);

  const dialogTitle = 'Confirm operation'

  const defaultDialogContent = 'Are you sure to cancel the order?'

  const [dialogContent, setDialogContent] =  useState(defaultDialogContent);

  const [orders, setOrders] = useState([]);
  // console.log(orders)

  const [pageCount, setPageCount] = useState(1);

  const [curPage, setCurPage] = useState(1);

  const [isOpen, setIsOpen] = useState(false)

  const [targetOrderId, setTargetOrderId] = useState(0)

  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();

  const navigate = useNavigate();

  const [page, setPage] = useState(params.page ?? 1)

  const [cancelFee, setCancelFee] = useState(0)

  const handlePageChange = (event, value) => {
    setPage(value)
    // fetchOrders(value);
    
    navigate('/OrderLst/' + value)
    fetchOrders(value)
  };

  const fetchOrders = (page) => {
    Api.get('/customer/orderList', {page: page})
      .then(res => {
        setShowLoader(false);
        setOrders(res.data.data);
        setPageCount(res.data.last_page);
        setCurPage(res.data.current_page);
      })
      .catch(err => {
        setShowLoader(false);
        console.log(err);
      })
  }

  const handleConfirmCallback = () => {
    Api.post('/customer/cancelOrder', {orderId: targetOrderId})
    .then(res => {
      if (res.code !== 1000) {
        enqueueSnackbar(res.msg,  {variant: 'error'})
      } else {
        enqueueSnackbar('Cancel successfully',  {variant: 'success'})
        fetchOrders(page)
      }
      setIsOpen(false)
      
    })
    .catch(err => {
      console.log(err);
    })
  }

  const handleCancelOrder = (orderId, orderStatus) => {
    if (orderStatus >= 2) {
      setDialogContent('Cancel order in this stage will be charged $'+cancelFee+' as a cancellation fee. Are you sure to cancel the order?')
    } else {
      setDialogContent(defaultDialogContent)
    }
    setTargetOrderId(orderId)
    setIsOpen(true)
    console.log(orderId)
    console.log(orderStatus)
    
    // setConfirmCallback(clickCallback)
  }

  const fetchCancelFee = () => {
    Api.get('/customer/cancelFee', {})
      .then(res => {
        setCancelFee(res.data.cancel_fee)
      })
      .catch(err => {
        console.log(err);
      })
  }

  React.useEffect(() => {
    setShowSidebar(1)
    setSelectedIndex(2)
    fetchOrders(page)
    fetchCancelFee()
  }, []);

  return (
    <Container component="main" maxWidth="lg">
    <Loader showLoader={showLoader} />  
    <Box component="form" noValidate sx={{ flexGrow: 1, }}>
        <ResponsiveDialog title={dialogTitle} 
                          content={dialogContent} 
                          isOpen={isOpen} 
                          setIsOpen={setIsOpen}
                          confirmCallback={handleConfirmCallback}
                          />
        <Toolbar />
        {/* <Typography variant="h6" gutterBottom>
            Order List
        </Typography> */}
        {orders.map((order) => (
        <Paper key={order.id} elevation={3} sx={{ padding: 2, mt:2}}>
          <Typography variant="h6">Order Number: {order.order_no}</Typography>
          <Grid container spacing={2} alignItems="center" >
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">Order Date: {order.created_date}</Typography>
              <Typography variant="subtitle1">Order Price: ${order.total_price}</Typography>
              <Typography variant="subtitle1" >Order Status: <span style={{color: 'red'}}>{order.order_status_desc}</span></Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Repair job: &nbsp;
                {order.job_list[0].phone_name} {order.job_list[0].repair_name}
                {order.job_list.length > 1 ? (
                  `...(${order.job_list.length - 1})`  
                ) : null}
              </Typography>
              
              <Typography variant="subtitle1">Accessories: &nbsp;
                {order.acc_list.length > 0 ? (
                  `${order.acc_list[0].quantity}x ${order.acc_list[0].acc_name}`  
                ) : ' None'} 
                {order.acc_list.length > 1 ? (
                  `...(${order.acc_list.length - 1})`  
                ) : null}
                
              </Typography>

              <Typography variant="subtitle1">
                Technician: &nbsp;
                <Tooltip
                  title={
                    <Box display="flex" alignItems="center">
                      <Avatar src={order.technician.avatar} alt={order.technician.first_name} sx={{ mr: 1 }} />
                      <Box>
                        <Typography variant="body2">{order.technician.first_name}</Typography>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">({parseFloat(order.technician.cs_rating).toFixed(2)})</Typography>
                          <Rating
                            value={parseFloat(order.technician.cs_rating)}
                            precision={0.1}
                            readOnly
                            sx={{ ml: 1 }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  }
                  arrow
                >
                  <Link to={`/TechnicianIntro/${order.technician.id}`}>{order.technician.first_name}</Link>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} sx={{ justifyContent: 'flex-end' }}>
              <Typography>
              <Button
                component={Link}
                to={`/OrderDetail/${order.id}`}
                variant="contained"
                color="primary"
              >
                View
              </Button>
              </Typography>
              {[1, 2, 3].includes(order.order_status) ? (
                <Typography sx={{mt: 1}}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {handleCancelOrder(order.id, order.order_status)}}
                >
                  Cancel
                </Button>
                </Typography>
              ) : null}

              {order.order_status == 4 && !order.cs_id ? (
                <Typography sx={{mt: 1}}>
                <Button
                  component={Link}
                  to={`/OrderRating/${order.id}`}
                  variant="outlined"
                  color="primary"
                >
                  Feedback
                </Button>
                </Typography>
              ) : null}
              
              
            </Grid>
            
            <Grid item xs={12} sm={12}>
              {order.img_list.map((img, index) => (
                              
                <img key={index} src={img.img_path} alt="Repair" style={{ maxWidth: '64px', maxHeight: '64px', marginRight:'10px' }} />
              ))}
          
            </Grid>
          </Grid>
        </Paper>
      ))}
      
      {orders.length > 0 ? 
        <Pagination count={pageCount} page={curPage} onChange={handlePageChange} color="primary" sx={{mt:2}} />
      : null}
        
    
    </Box>
    </Container>
  )
}

export default OrderLst;
