import React, {useState} from 'react';
import Cookies from 'js-cookie';
import Api from '../utils/request/http';
// import { useMediaQuery } from '@mui/material';
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import DevAccessValidation from './DevAccessValidation';
import config from '../config';
import '../styles/custom_styles.css';
import { Tooltip, IconButton, Avatar, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import NotificationBar from "./NotificationBar";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import $ from 'jquery';

export default function Navbar({ isIndex }) {

  // const navigate = useNavigate()

  const urlArray = {
    'home': ['/'],
    'repair': ['/Repair'],
    'services': ['/Services'],
    'career': ['/Career'],
    'aboutus': ['/AboutUs'],
    'contactus': ['/ContactUs'],
    'signin': ['/SignIn'],
    'signup': ['/SignUp', '/TechSignUp'],
    'userhome': ['/UserHome'],
    'order': ['/OrderLst', '/OrderDetail', '/OrderRating'],
  };

  const cur_location = useLocation()
  console.log('===============2===========' + cur_location.pathname);

  // const curEnv = config.env
  // const dev_verify_status = localStorage.getItem('dev_verify_status');
  // if (curEnv !== 'PROD' && dev_verify_status !== 1 && cur_location.pathname !== '/') {
  //   window.location.href = '/'
  // }

  const { enqueueSnackbar } = useSnackbar();

  // const isMobile = useMediaQuery('(max-width: 600px)');
  const userToken = Cookies.get('acc_token')
  if (userToken) {
    Api.setHeaders({ 'Authorization': `Bearer ${userToken}` })
  }

  const handleSignOut = () => {
    Api.post('/customer/logout')
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg, { variant: 'error' })
          return
        }
        enqueueSnackbar(data.msg, { variant: 'success' })
        Cookies.remove('acc_token')
        window.location.href = '/'
      }
      )
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error', { variant: 'error' })
      });
  };

  const handleMenuClick = () => {
    document.getElementById('header-hidden').checked = true;
  }

  const [sharedUrl, setSharedUrl] = useState('');
  React.useEffect(() => {
    // jQuery code to handle hover events
    $('#user-avatar').hover(
      function() {
        $('#user-menu').fadeIn(200);
      },
      function() {
        setTimeout(() => {
          if (!$('#user-menu:hover').length) {
            $('#user-menu').fadeOut(200);
          }
        }, 1000);
      }
    );
  
    $('#user-menu').hover(
      function() {
        $(this).show();
      },
      function() {
        $(this).fadeOut(200);
      }
    );
      Api.post('/customer/fetchQrCode')
          .then(res => {
              setSharedUrl(res.data.qrCode);
          })
          .catch(error => {
              console.error("Error checking promotion code:", error);
          });
  }, []);

  const shareToFacebook = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharedUrl)}`;
    window.open(shareUrl, '_blank');
  };

  const shareToTwitter = () => {
      const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(sharedUrl)}&text=Check%20out%20this%20awesome%20website!`;
      window.open(shareUrl, '_blank');
  };

  return (

    <>
      {cur_location.pathname !== '/' ? <DevAccessValidation /> : null}
      {/* <AppBar
        position="fixed"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` , zIndex: (theme) => theme.zIndex.drawer + 1}}> */}
      <header className={isIndex ? "header header-over" : "header"}>
        <input id="header-default" type="radio" className="collapse" defaultChecked name="siteheader" />
        <input id="header-shown" type="radio" className="collapse" name="siteheader" />
        <input id="header-hidden" type="radio" className="collapse" name="siteheader" />
        <div className={isIndex ? "infobar transparent bottom-separator xs-hidden" : "infobar xs-hidden"}>
          <div className="container">
            <div className="cols-list pull-left cols-md">
              <div className="list-item">
                <span className="info-icon"><i className="fas fa-question-circle"></i>&nbsp;help@mrxpress.com.au &nbsp; Or &nbsp;<i class="fas fa-phone-alt"></i> &nbsp;  0411 93 73 72 </span>
              </div>
            </div>
            <div className="cols-list pull-right cols-md socials">
              {!userToken ? (
                      <div className="list-item"></div>
                  ) : (
                      <div className="list-item"><Link to="/PromotionCode"><MonetizationOnIcon
                          fontSize="small"></MonetizationOnIcon>INVITE A FRIEND FOR $10!</Link></div>
                  )}
            <div className="list-item">
                <Tooltip
                    title={
                        <div>
                            <QRCode value={sharedUrl}/>
                            <div style={{textAlign: 'center', marginTop: '10px'}}>
                                <IconButton data-testid="facebook-icon" onClick={shareToFacebook} style={{color: 'white'}}>
                                    <FacebookIcon/>
                                </IconButton>

                                <IconButton data-testid="twitter-icon" onClick={shareToTwitter} style={{color: 'white'}}>
                                    <TwitterIcon/>
                                </IconButton>
                                {/*<IconButton onClick={shareToInstagram} style={{ color: 'white' }}>*/}
                                {/*    <i className="fab fa-instagram"></i>*/}
                                {/*</IconButton>*/}

                            </div>
                        </div>
                    }
                >
                    <IconButton style={{color: 'white'}}>
                        <ShareIcon style={{ fontSize: '20px' }}/>
                    </IconButton>
                </Tooltip>
            </div>
              <div className="list-item"><a href="https://www.facebook.com/MobileRepairXpress"><i className="fab fa-facebook-f"
                aria-hidden="true"></i>
              </a></div>

              <div className="list-item"><a href="https://www.instagram.com/mrxpress.admin"><i
                className="fab fa-instagram" aria-hidden="true"></i>
              </a></div>

            </div>
          </div>
        </div>

        <nav className={isIndex ? "stick-menu menu-wrap simple line transparent" : "stick-menu menu-wrap simple line"}>
          <div className="menu-container menu-row">
            <div className="logo">
              <Link to="/">
                <img src="/assets/images/parts/mrxpress-logo.svg" alt="MRXpress" />
              </Link>
            </div>
            <div className="header-toggler pull-right xs-shown">
              <label className="header-shown-sign" htmlFor="header-hidden"><i className="fas fa-times" aria-hidden="true"></i></label>
              <label className="header-hidden-sign" htmlFor="header-shown"><i className="fas fa-bars" aria-hidden="true"></i></label>
            </div>
            <div className="clearfix xs-shown"></div>
            <div className="menu">
              <ul className="menu-items" >
                <li onClick={handleMenuClick} className={urlArray['home'].indexOf(cur_location.pathname) !== -1 ? 'active' : ''}>
                  <Link to="/">Home</Link>
                </li>

                <li onClick={handleMenuClick} className={`nav-item ${urlArray['repair'].indexOf(cur_location.pathname) !== -1 ? 'active' : ''}`}>
                  <Link to="/Repair" className="nav-link">Book a repair</Link>
                </li>

                <li onClick={handleMenuClick} className={urlArray['services'].indexOf(cur_location.pathname) !== -1 ? 'active' : ''}>
                  <Link to="/Services">Services</Link>
                </li>

                <li onClick={handleMenuClick} className={urlArray['career'].indexOf(cur_location.pathname) !== -1 ? 'active' : ''}>
                  <Link to="/Career">Career</Link>
                </li>

                <li onClick={handleMenuClick} className={urlArray['aboutus'].indexOf(cur_location.pathname) !== -1 ? 'active' : ''}>
                  <Link to="/AboutUs">About Us</Link>
                </li>

                <li onClick={handleMenuClick} className={urlArray['contactus'].indexOf(cur_location.pathname) !== -1 ? 'active' : ''}>
                  <Link to="/ContactUs">Contact Us</Link>
                </li>

                {!userToken ? (
                  <>
                    <li onClick={handleMenuClick} className={urlArray['signin'].indexOf(cur_location.pathname) !== -1 ? 'active' : ''}>
                      <Link to="/SignIn">Sign In</Link>
                    </li>

                    <li onClick={handleMenuClick} className={urlArray['signup'].indexOf(cur_location.pathname) !== -1 ? 'active' : ''}>
                      <Link to="/SignUp">Sign Up</Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li><NotificationBar /></li>
                    <div className="user-profile" style={{ display: 'flex', alignItems: 'center' }}>
                      <div id="user-avatar" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                        <img 
                          src={'/default-avatar.png'} 
                          alt='{user.firstName}'
                          style={{ 
                            width: '32px', 
                            height: '32px', 
                            borderRadius: '50%',
                            marginRight: '8px' 
                          }} 
                        />
                        <span></span>
                      </div>
                      <div id="user-menu" 
                        style={{ 
                          display: 'none',
                          position: 'absolute',
                          top: '100%',
                          right: '0',
                          backgroundColor: isIndex ? '#666' : '#fff',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          borderRadius: '4px',
                          minWidth: '150px',
                          zIndex: 1000
                        }}>
                        <Link to="/OrderLst" style={{ display: 'block', padding: '10px 15px' }}>Orders</Link>
                        <Link to="/UserHome" style={{ display: 'block', padding: '10px 15px' }}>Profile</Link>
                        <div onClick={handleSignOut} className="menu-item" style={{ display: 'block', padding: '10px 15px', cursor: 'pointer' }}>Sign Out</div>
                      </div>
                    </div>
                  
                  </>

                )}

              </ul>

              <div className="clearfix"></div>
              <div className="line-right xs-hidden"></div>

            </div>
          </div>
        </nav>


      </header>
      {/* </AppBar> */}
    </>
  );
}