import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { makeStyles } from '@mui/styles';
import { createTheme,  } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Api from '../utils/request/http';
import Loader from '../components/Loader';

const theme = createTheme();

const useStyles = makeStyles(() => ({
  
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  productImage: {
    maxWidth: '100%',
    height: 'auto',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  quantityContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


export default function Accessories({ allData, setAllData, pageDat, setPageDat } ) {
  console.log('pageDat:',pageDat);
  const classes = useStyles();

  const [showLoader, setShowLoader] = React.useState(true);

  const [pageCount, setPageCount] = useState(1);

  const [curPage, setCurPage] = useState(1);

  const [catId, setCatId] = useState(0);

  const [categories, setCategories] = useState([]);

  const [products, setProducts] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const addToCart = (productId, stock) => {
    setAllData((prevData) => {
      const updatedCart = { ...prevData };
      if (updatedCart.cart[productId]) {
        if (updatedCart.cart[productId] >= stock) {
          enqueueSnackbar('Out of stock',  {variant: 'error'})
          return updatedCart;
        }
        updatedCart.cart[productId]++;
      } else {
        updatedCart.cart[productId] = 1;
      }
      
      return updatedCart;
    });
  };

  const removeFromCart = (productId) => {
    setAllData((prevData) => {
      const updatedCart = { ...prevData };
      if (updatedCart.cart[productId] > 1) {
        updatedCart.cart[productId]--;
      } else {
        delete updatedCart.cart[productId];
      }
      
      return updatedCart;
    });
  };

  const handlePageChange = (event, value) => {
    fetchProducts(value, catId);
  };

  const fetchCategories = () => {
    Api.get('/customer/categoriesList', {})
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          setCategories(data.data);
          setPageDat({ ...pageDat, categories: data.data });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  const handleCategory = (id, isLeave) => {
    if (!isLeave) {
      return;
    }
    setCatId(id);
    fetchProducts(1, id);
  };    
  
  const fetchProducts = (page, catId) => {
    setShowLoader(true);
    // obtain accessories according to selected phone type 
    const phoneTypeArr = allData.repairs.map(repair => repair.phoneType);
    Api.get('/customer/accessoriesList', {catId: catId, page: page, phoneTypeArr: phoneTypeArr})
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          setProducts(data.data.data);
          setPageCount(data.data.last_page);
          setCurPage(data.data.current_page);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  React.useEffect(() => {
    fetchProducts();
    if (pageDat.categories) {
      setCategories(pageDat.categories);
      return;
    }
    fetchCategories();
    // console.log('component', componentDat)
    
  }, []);

  return (
    <React.Fragment>
      <Loader showLoader={showLoader} />
      <div style={{ display: 'flex' }}>
      <TreeView
        aria-label="multi-select"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
        sx={{ height: 216, flexGrow: 1, maxWidth: 400, overflowY: 'auto', marginRight: '20px', minWidth: '18%', minHeight: '50vh'}}
      >
        {categories.map((category) => (
          <TreeItem nodeId={category.id.toString()} label={category.name} key={category.id} onClick={()=>{handleCategory(category.id, category.children.length > 0 ? 0 : 1)}}>
            {category.children.map((child) => (
              <TreeItem nodeId={child.id.toString()} label={child.name} key={child.id} onClick={()=>{handleCategory(child.id, child.children.length > 0 ? 0 : 1)}}>
                {child.children.map((leaf) => (
                  <TreeItem nodeId={leaf.id.toString()} label={leaf.name} key={leaf.id} onClick={()=>{handleCategory(leaf.id, 1)}} />
                  
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
      </TreeView>
      {products.length > 0 ? <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3}key={product.id}>
            <Paper className={classes.paper}>
              <img
                src={product.image}
                alt={product.name}
                className={classes.productImage}
                style={{maxWidth:'150px', maxHeight:'150px'}}
              />
              <Typography variant="h6">{product.name}</Typography>
              <Typography variant="subtitle1">{product.price}</Typography>
              {allData.cart[product.id] ? (
                <div className={classes.quantityContainer}>
                  <IconButton
                    aria-label="Remove from Cart"
                    color="primary"
                    onClick={() => removeFromCart(product.id)}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Typography variant="body1">{allData.cart[product.id]}</Typography>
                  <IconButton
                    aria-label="Add to Cart"
                    color="primary"
                    onClick={() => addToCart(product.id, product.stock)}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              ) : (
                <IconButton
                  aria-label="Add to Cart"
                  color="primary"
                  onClick={() => addToCart(product.id)}
                >
                  <ShoppingCartIcon />
                </IconButton>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid> : <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Typography variant="h6" align="center">
                  No accessories are available for this device model
                  </Typography>
                </Grid>}
      
    </div>
    <div className={classes.paginationContainer}>
      <Pagination count={pageCount} page={curPage} onChange={handlePageChange} color="primary" />
    </div>
    </React.Fragment>
  );
}
