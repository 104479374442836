import React, {useEffect, useRef, useState} from 'react';
import '../styles/NotificationBar.css';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material//Typography';
import {Button} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import config from '../config'
import Api from '../utils/request/http.js';
import Pusher from 'pusher-js';
import Cookies from "js-cookie";


const NotificationBar = () => {
    const [isBarVisible, setIsBarVisible] = useState(false);
    const [messages, setMessages] = useState([]);
    const [userId, setUserId] = useState();
    const [channel, setChannel] = useState({});
    const messagesLength = useRef(messages.length);
    const pusherSecret = config.pusher_app_key;


    const fetchUser = async () => {
        Api.get('/customer/me', {})
            .then((data)=> {
                if (data.code !== 1000) {
                    enqueueSnackbar(data.msg,  {variant: 'error'})
                }else{
                    setUserId(data.data.id);
                    bindChannel(data.data.id);
                }
            })
            .catch((error) => {
                enqueueSnackbar('Network error',  {variant: 'error'})
            });
    }

    function readAll()
    {
        Api.post('/customer/read-all', {'readAll': 'user'})
            .then(()=> {
                    setMessages([]);
                }
            )
            .catch((error) => {
                enqueueSnackbar('Network error',  {variant: 'error'})
            });

    }

    const bindChannel = (uid) => {
        const pusher = new Pusher(pusherSecret, {
            cluster: 'ap4',
            authEndpoint: config.api+'/customer/pusher/auth',
            auth: {
                headers: {
                    'Authorization': `Bearer ${Cookies.get('acc_token')}`
                }
            }
        });

        const channel = pusher.subscribe(`private-user-${uid}`);

        channel.bind('new-message', function(data) {
            console.log(data);
            setMessages(data);
        });

        setChannel(channel);
    }

    const unbindChannel = () => {
        if (!channel) return;
        // channel.unsubscribe();
    }

    useEffect(() => {
        fetchUser();
        Api.post('/customer/get-message', {})
            .then((data)=> {
                setMessages(data.data.messages);
                }
            )
            .catch((error) => {
                enqueueSnackbar('Network error',  {variant: 'error'})
            });

        return () => {
            unbindChannel();
        };
    }, [userId]);

    const toggleBar = () => {
        setIsBarVisible(!isBarVisible);
    };

    //detail operation
    const orderMessage = (id) =>{
        window.location.assign(`/OrderDetail/${id}`);
    }

    //delete the message
    const deleteMessage = (id) =>{
        Api.post('/customer/message/read', {orderId: id})
            .then(res => {
                if (res.code !== 1000) {
                    enqueueSnackbar(res.msg,  {variant: 'error'})
                }else{
                    setMessages(prevMessages => prevMessages.filter(message => message.m_id !== id));
                }
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
        //fetchData();
    }




    return (
        <>
            <li className="menu-item menu-icon" onClick={toggleBar}>
                <a>
                    <i className="fa fa-bell"></i>
                    {messages.length > 0 && <span className="badge">{messages.length}</span>}
                </a>
            </li>
            <div className={`notification-bar ${isBarVisible ? 'visible' : ''}`}>
                {messages.length>0 &&
                    <div className="read-all-container">
                <Button
                    variant="contained"
                    color="success"
                    className="read-all"
                    onClick={readAll}>
                    Read ALl</Button></div>
                }
                {messages.length==0 &&
                    <div className="empty">
                    <p>Empty</p>
                    </div>
                }
                <div style={{height: '1px'}}></div>
                {messages.map((message, index) => (
                    <React.Fragment key={index}>
                    <Card>
                        <CardContent className="card-bar">
                            <Typography className="card-content" component="h5">
                                Order Status Change: #{message.order_no}
                            </Typography>
                            <Typography className="content" color="textSecondary" component="h5">
                                {message.content}
                            </Typography>
                            <Typography className="time" component="h5">
                                {message.time}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                onClick={()=>orderMessage(message.order_id)}
                                variant="contained"
                                color="primary"
                                className="button"
                                >
                                Check</Button>
                            <Button
                                onClick={()=>deleteMessage(message.m_id)}
                                variant="outlined" color="error" className="button">
                                Read</Button>
                        </CardActions>
                    </Card>
                    <Divider variant="inset" component="li" />
                    </React.Fragment>
                ))}
            </div>
        </>
    );
};

export default NotificationBar;
