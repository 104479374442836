import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import ImageUploader from '../components/ImageUploader';
import Loader from '../components/Loader';
import TextField from '@mui/material/TextField';

const PhoneRepair = React.memo(({ allData, setAllData, pageDat, setPageDat }) => {
  // console.log('step:',  pageDat, setPageDat);

  const [showLoader, setShowLoader] = React.useState(true);

  const [phoneTypeLst, setPhoneTypeLst] = useState([]);

  const [repairTypeLst, setRepairTypeLst] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const [maxId, setMaxId] = useState(1)
  
  const [formFields, setFormFields] = useState([{ id: 1, repairType: '', phoneType: '' }]);

  const handleAddField = () => {
    const newField = { id: maxId + 1, repairType: '', phoneType: '' };
    const updateFields = [...formFields, newField]
    setMaxId(maxId + 1)
    setFormFields(updateFields);
    setAllData((prevData) => {
      const updatedRepairs = { ...prevData };
      updatedRepairs.repairs = updateFields
      
      return updatedRepairs;
    });
  };

  const handleRemoveField = (id) => {
    const updatedFields = formFields.filter((field) => field.id !== id);
    setFormFields(updatedFields);
    setAllData((prevData) => {
      const updatedRepairs = { ...prevData };
      updatedRepairs.repairs = updatedFields
      
      return updatedRepairs;
    });
  };

  const handleChange = (id, field, value) => {
    const updatedFields = formFields.map((formField) =>
      formField.id === id ? { ...formField, [field]: value } : formField
    );
    setFormFields(updatedFields);
    setAllData((prevData) => {
      const updatedRepairs = { ...prevData };
      updatedRepairs.repairs = updatedFields
      
      return updatedRepairs;
    });
  };
  

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({ ...formData, [name]: value });
    
  //   // console.log(formData.phoneType, formData.repairType)
  // };

  const fetchPhoneRepair = () => {
    Api.get('/customer/phoneRepair', {})
      .then((data) => {
        setShowLoader(false)
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          
          setPhoneTypeLst(data.data.phoneTypeArr);
          setRepairTypeLst(data.data.repairType);
          // console.log('phoneRepair2',componentDat, setComponentDat)
          setPageDat({ ...pageDat, phoneRepairDat: data.data });
        }
      })
      .catch((error) => {
        setShowLoader(false)
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };
  
  const addImgCallback = (images) => {
    
    images.forEach(file => {
      const formData = new FormData();
      formData.append('image', file);
      // Api.setHeaders({'Content-Type': 'multipart/form-data'});
      Api.post('/customer/imageUpload', formData, {'Content-Type': 'multipart/form-data'})
        .then(data => {
          if (data.code !== 1000) {
            enqueueSnackbar(data.msg,  {variant: 'error'})
          } else {

            setAllData((prevData) => {
              const updatedImgs = { ...prevData };
              updatedImgs.imgs.push(file)
              updatedImgs.imgPath.push(data.data.path)
              return updatedImgs;
            });
          }
        })
        .catch(error => {
          console.error('Error:', error);
          enqueueSnackbar('Network error',  {variant: 'error'})
        });
    })  

    
  };

  const delImgCallback = (index) => {
    setAllData((prevData) => {
      const updatedImgs = { ...prevData };
      updatedImgs.imgs = updatedImgs.imgs.filter((_, i) => i !== index)
      updatedImgs.imgPath = updatedImgs.imgPath.filter((_, i) => i !== index)
      return updatedImgs
    });
  }


  React.useEffect(() => {
    if (pageDat.phoneRepairDat) {
      setPhoneTypeLst(pageDat.phoneRepairDat.phoneTypeArr);
      setRepairTypeLst(pageDat.phoneRepairDat.repairType);
      // setFormData({ ...formData, ...allData });
      if (allData.repairs.length > 0) {
        setFormFields(allData.repairs);
      }
      setShowLoader(false)
      return;
    }
    fetchPhoneRepair();
    
    // console.log('component', componentDat)
    

  }, []);

  
  return (
    <React.Fragment>
      <Loader showLoader={showLoader} />
      <Typography variant="h6" gutterBottom>
        Select device
      </Typography>
      {formFields.map((field, index) => (
      <Grid container spacing={2} key={field.id}>
        <Grid item xs={12} sm={4} >
        <FormControl variant="standard" sx={{ m: 1, width: '100%'}}>
          <InputLabel id="demo-simple-select-standard-label" required>Phone type</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={field.phoneType ?? ''}
            name='phoneType'
            onChange={(e) => handleChange(field.id, 'phoneType', e.target.value)}
            label="phoneType"
            // error={true}
          >
            {phoneTypeLst.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                <div key={item.id} dangerouslySetInnerHTML={{ __html: item.name }} />
              </MenuItem>
            ))}
            
          </Select>
          
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} >
          <FormControl variant="standard" sx={{ m: 1, width: '100%'}}>
            <InputLabel id="demo-simple-select-filled-label" required>Repair type</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={field.repairType ?? ''}
              name='repairType'
              onChange={(e) => handleChange(field.id, 'repairType', e.target.value)}
              label="phoneType"
            >
              {repairTypeLst[field.phoneType] && repairTypeLst[field.phoneType].map((item) => (
                <MenuItem value={item.repair_id} key={item.repair_id}>{item.repair_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            disabled
            sx={{
              '& .MuiInputBase-root': {
                '& .Mui-disabled': {
                  WebkitTextFillColor: 'black', // 设置禁用状态下的字体颜色
                },
                '&:before': {
                  borderBottom: 'none',
                },
              },
              margin: '15px'
            }}
            id={"text" + field.id}
            name="price_show"
            label="Repair price"
            fullWidth
            variant="standard"
            value={
              repairTypeLst[field.phoneType] && 
              repairTypeLst[field.phoneType].find(item => item.repair_id === field.repairType)?.price ? 
                "$" + repairTypeLst[field.phoneType].find(item => item.repair_id === field.repairType).price : ''}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              disableUnderline: true,
            }}
          />

          {/* {repairTypeLst[field.phoneType] && repairTypeLst[field.phoneType].map((item) => (
            item.repair_id === field.repairType && <Typography gutterBottom key={item.repair_id} sx={{ml: 2}}>
              ${item.price}
            </Typography>
          ))} */}
        </Grid>
        <Grid item xs={12} sm={2}>
        <Box display="flex" alignItems="center" style={{marginTop: '20px'}}>
          {formFields.length > 1 && (
            <IconButton
              color="secondary"
              onClick={() => handleRemoveField(field.id)}
            >
              <RemoveIcon />
            </IconButton>
          )}
          {formFields.length === (index + 1) && (
            <IconButton color="primary" onClick={handleAddField}>
              <AddIcon />
            </IconButton>
          )}
          {/* Show price string */}
          {/* {repairTypeLst[field.phoneType] && repairTypeLst[field.phoneType].map((item) => (
            item.repair_id === field.repairType && <Typography gutterBottom key={item.repair_id} sx={{ml: 2}}>
              ${item.price}
            </Typography>
          ))} */}
        </Box>
        </Grid>
        
      </Grid>
      ))}

      <Typography variant="h6" gutterBottom sx={{mt:4}}>
        Upload photo
      </Typography>
      <ImageUploader defaultImgs={allData.imgs} addImgCallback={addImgCallback} delImgCallback={delImgCallback} maxImages={4}/>
    </React.Fragment>
  );
});

export default PhoneRepair;
