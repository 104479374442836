import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';

function ImageUploader({defaultImgs, addImgCallback, delImgCallback, maxImages}) {

  const { enqueueSnackbar } = useSnackbar();

  const onDrop = (acceptedFiles) => {
    console.log(defaultImgs.length)
    if ((defaultImgs.length + acceptedFiles.length) > maxImages ) {
      return;
    }

    addImgCallback(acceptedFiles)
  }

  const deleteImage = (index) => {

    delImgCallback(index)
  };

  const handleReject = (e) => {
    enqueueSnackbar(e[0].errors[0]['code'], {variant: 'error'})
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    },
    maxFiles:maxImages,
    maxSize: 5000000,
    onDropRejected: handleReject,
  });



  return (
    <div>
      <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or <Button variant="contained" size='small' >select files</Button></p>
        <p>Max {maxImages} images</p>
        <p>Each image no larger than 5MB</p>
      </div>
      <div >
      {defaultImgs.map((file, index) => (
          <div key={index} style={{ position: 'relative', maxWidth: '100px', maxHeight: '100px', display: 'inline-flex', margin: 3}}>
            <img src={URL.createObjectURL(file)} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
            <IconButton sx={{padding:0}}
              style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
              onClick={() => deleteImage(index)}
            >
            <HighlightOffIcon />
          </IconButton>
        </div>
        ))}
      </div>
      {/* <button onClick={handleUpload}>Upload</button> */}
    </div>
  );
}

export default ImageUploader;
