import axios from 'axios';
import jsCookie from 'js-cookie';
// import { useNavigate } from 'react-router-dom';

class ApiRequest {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL,
    });
  }

  setHeaders(headers) {
    this.client.defaults.headers.common = {
      ...this.client.defaults.headers.common,
      ...headers,
    };
  }

  // navigate = useNavigate();

  async get(url, params = {}, headers = {}) {
    try {
      const response = await this.client.get(url, { params, headers: headers });
      if (response.data.code === 1002) {
        jsCookie.remove('acc_token')
        window.location.href = '/SignIn';
        return;
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async post(url, data = {}, headers = {}) {
    try {
      const response = await this.client.post(url, data, {headers: headers});
      if (response.data.code === 1002) {
        jsCookie.remove('acc_token')
        window.location.href = '/SignIn';
        return;
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async put(url, data = {}) {
    try {
      const response = await this.client.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(url) {
    try {
      const response = await this.client.delete(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default ApiRequest;
