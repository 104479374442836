import React from "react";

export default function HeaderInit(){
  React.useEffect(() => {
    const removeList = []
    const scriptList = [
      '/assets/jquery/jquery-3.3.1.js',
      '/assets/flexslider/jquery.flexslider-min.js',
      '/assets/parallax.js/parallax.js',
      '/assets/owlcarousel2/owl.carousel.min.js',
      '/assets/shuffle/shuffle.min.js',
      '/assets/waypoints/jquery.waypoints.min.js',
      '/assets/chosen/chosen.jquery.min.js',
      '/assets/jquery-ui-custom/jquery-ui.min.js',
      '/assets/pentix/js/pentix.js',
      
    ];
    scriptList.forEach((item) => {
      const script = document.createElement('script');
      script.src = item;
      script.async = false;  
      document.head.appendChild(script);
      removeList.push(script);
    });
    // showPage()
    // return () => {
    //   removeList.forEach((item) => {
    //     document.head.removeChild(item);
    //   });
    //   // window.google = null;
    // };
    
}, []);
}