import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FormControlLabel } from '@mui/material';
import StripeInput from '../components/StripeInput';
import { CardExpiryElement,CardNumberElement,CardCvcElement } from '@stripe/react-stripe-js';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Api from '../utils/request/http';
import Loader from '../components/Loader';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function PaymentForm({ allData, setAllData, pageDat, setPageDat }) {

  const [showLoader, setShowLoader] = React.useState(false);

  const [formData, setFormData] = React.useState({});

  const [isCheck, setIsCheck] = React.useState(false);

  const [isShow, setIsShow] = React.useState(true);

  const [last4, setLast4] = React.useState('');

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate()

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setAllData({ ...allData, ...formData });
  };

  const handleCheckboxChange = (event) => {
    setIsCheck(event.target.checked);
    setAllData({ ...allData, saveCard: event.target.checked });
  }

  const changePaymentCard = () => {
    
    setIsShow(true);
    setAllData((prevData) => {
      const updateUser = { ...prevData };
      updateUser.user.strpePMId = ''
      
      return updateUser;
    });
  }

  const fetchPaymentMethod = (strpePMId) => {
    setShowLoader(true);
    const pmId = strpePMId ? strpePMId : allData.user.strpePMId;
    Api.get('/payment/retrievePaymentMethod', {pmId: pmId})
      .then((data) => {
        setShowLoader(false);
        if (data.code !== 1000) {
          changePaymentCard();
        } else {
          setIsShow(false);
          setLast4(data.data.cards.last4);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });
  };

  const fetchUser = async () => {
    setShowLoader(true);
    Api.get('/customer/me', {})
      .then((data) => {
        setShowLoader(false);
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
          return
        }
        const userDetail = {
          // firstName: data.data.first_name, 
          // lastName: data.data.last_name, 
          // address: data.data.address,
          email: data.data.email,
          mobile: data.data.mobile,
          strpeCustomerId: data.data.stripe_customer_id,
          strpePMId: data.data.stripe_pm_id,
        }
        setAllData({...allData, user: userDetail});
        if (data.data.stripe_pm_id) {
          fetchPaymentMethod(data.data.stripe_pm_id);
        } else {
          setIsShow(true);
        }
        
      })
      .catch((error) => {
        setShowLoader(false);
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  }

  const userToken = Cookies.get('acc_token')
  React.useEffect(() => {

    if (allData.user && allData.user.strpePMId) {
      fetchPaymentMethod(allData.user.strpePMId);
      return
    }

    if (allData.user) {
      return
    }

    if (userToken && !allData.user) {
      fetchUser();
    } else {
      enqueueSnackbar('Please login first',  {variant: 'error'})
      localStorage.setItem('repair_alldata', JSON.stringify(allData));
      localStorage.setItem('repair_step', 3)
      navigate('/SignIn')
      
    }
    
  }, []);
  
  return (
    <>
      <Loader showLoader={showLoader} />
      <Typography variant="h6" gutterBottom>
        Payment information
      </Typography>
      <Grid container spacing={3}>
        {isShow ? (
          <>
            <Grid item xs={12} md={6}>
            <TextField
              required
              id="cardName"
              name="cardName"
              label="Name on card"
              fullWidth
              autoComplete="cc-name"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              // placeholder='Firstname Lastname'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="cardNumber"
              name="cardNumber"
              label="Card number"
              fullWidth
              autoComplete="cc-number"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              // onChange={handleChange}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                    component: CardNumberElement
                },
            }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              required
              id="expDate"
              name="cardExpDate"
              label="Expiry date"
              fullWidth
              autoComplete="cc-exp"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              // onChange={handleChange}
              InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                      component: CardExpiryElement
                  },
              }}
            />
  
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              required
              id="cvc"
              name='cardCvc'
              label="CVC"
              helperText="Last three digits on signature strip"
              fullWidth
              autoComplete="cc-csc"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              // onChange={handleChange}
              InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                      component: CardCvcElement
                  },
              }}
            />
          </Grid>
          <Grid item xs={12} alignItems="center">
            <FormControlLabel
              control={<Checkbox color="secondary" name="saveCard" value="yes" checked={isCheck} onChange={handleCheckboxChange} />}
              label="Remember credit card details for next time"
            />
          </Grid>
          </>
        ) : (
          <Grid item xs={12} >
            Place order with card ****{last4}
            <Button  variant="contained" onClick={changePaymentCard} sx={{ ml: 2 }}>
                Change card
            </Button>
          </Grid>
        )}
        
        <Grid item xs={12} >
        <img
          src="/stripe.png"
          alt="Mobile Fix Logo"
          style={{ height: '45px',  }}
        />
        </Grid>
      </Grid>
    </>
  )
}
