import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function TermsOfService() {
    return (
        <>
        <Helmet>
            <title>MR Xpress Terms of Service | Mobile Phone Repair</title>
            <meta name="description" content="Read MR Xpress’s Terms of Service. Learn more about our repair policies, conditions, and customer responsibilities." />
            <meta name="keywords" content="services, phone repair, MR Xpress" />
            <meta property="og:title" content="MR Xpress Terms of Service | Mobile Phone Repair" />
            <meta property="og:description" content="Read MR Xpress’s Terms of Service. Learn more about our repair policies, conditions, and customer responsibilities." />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="MR Xpress"></meta>
            <meta property="og:url" content="https://www.mrxpress.com.au/TermsOfService" />
            <link rel="canonical" href="https://www.mrxpress.com.au/TermsOfService" />
            <meta name="robots" content="index,follow"></meta>
            <meta name="googlebot" content="index,follow"></meta>
            {JSON.stringify({
                "@context": "https://schema.org ",
                "@type": "WebPage",
                "name": "Terms of Service",
                "description": "MR Xpress’s Terms of Service outline the conditions and policies for our mobile phone repair services.",
                "url": "https://www.mrxpress.com.au/TermsOfService",
                "lastReviewed": "2024-10-28",
                "publisher": {
                    "@type": "Organization",
                    "name": "MR Xpress",
                    "url": "https://www.mrxpress.com.au ",
                    "logo": "https://www.mrxpress.com.au/logo.png"
                }
            })}
        </Helmet>
        <div className="container legal-doc-styling">
            <h1>Terms of Service</h1>
            <p>
                MR Xpress Terms of Service<br/>
                    Effective Date: 21 May 2024
            </p>
            <p>
                Welcome to MR Xpress. These Terms of Service ("Terms") govern your access to and use of our website, mobile applications, and services ("Services"). By accessing or using our Services, you agree to be bound by these Terms.
            </p>
            <div>
                <p class="section-title">1. Definitions</p>
                <ul>
                    <li>"MR Xpress," "we," "us," or "our": Refers to MR Xpress Pty Ltd.</li>
                    <li>"User," "you," or "your": Refers to any individual or entity using our Services.</li>
                    <li>"Customer": An individual or entity requesting mobile device repair services.</li>
                    <li>"Technician": An individual or entity providing mobile device repair services through our platform.</li>
                    <li>"Admin": Individuals managing and overseeing platform operations.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">2. User Accounts</p>
                <ul>
                    <li>Eligibility: You must be at least 18 years old to use our Services.</li>
                    <li>Account Responsibilities: Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">3. Services</p>
                <ul>
                    <li>Service Description: MR Xpress provides a platform connecting customers with technicians for mobile device repairs.</li>
                    <li>Booking and Payments: Customers can book repair services and make payments through the platform. Technicians receive payments for services provided, minus a service fee.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">4. User Conduct</p>
                <ul>
                    <li>Prohibited Activities: Users must not engage in any activity that violates these Terms, including but not limited to fraudulent activities, misuse of the platform, or harassment of other users.</li>
                    <li>Compliance with Laws: Users must comply with all applicable laws and regulations while using our Services.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">5. Payments and Fees</p>
                <ul>
                    <li>Service Fees: MR Xpress charges a service fee of 2.5% on each transaction between customers and technicians.</li>
                    <li>Refunds: Refunds are processed according to our Refund and Cancellation Policy.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">6. Limitation of Liability</p>
                <ul>
                    <li>No Warranty: MR Xpress provides Services "as is" without any warranties.</li>
                    <li>Liability Limitation: MR Xpress's liability is limited to the maximum extent permitted by law. We are not liable for any indirect, incidental, or consequential damages arising from the use of our Services.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">7. Dispute Resolution</p>
                <ul>
                    <li>Arbitration: Any disputes arising out of or relating to these Terms or our Services will be resolved through binding arbitration in accordance with the rules of the Australian Centre for International Commercial Arbitration (ACICA).</li>
                </ul>
            </div>
            <div>
                <p class="section-title">8. Termination</p>
                <ul>
                    <li>Termination by MR Xpress: We may terminate or suspend your account and access to our Services at our sole discretion, without prior notice or liability, for any reason.</li>
                    <li>Termination by User: You may terminate your account at any time by following the instructions on our platform.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">9. Amendments</p>
                <ul>
                    <li>Changes to Terms: MR Xpress reserves the right to modify these Terms at any time. We will notify users of any changes by posting the updated Terms on our website.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">10. Contact Information</p>
                <p>For any questions or concerns about these Terms, please contact us at info@mrxpress.com.au.</p>
            </div>
        </div>
        </>
    );
}