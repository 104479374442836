import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import {Box, Typography, Container} from '@mui/material';
import Api from '../utils/request/http.js';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {isStringEmpty} from '../utils/isStringEmpty.js';
import {isEmailValid} from '../utils/isEmailValid.js';
import { useParams } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import config from '../config.js';

const ReCAPTCHA_SITE_KEY = config.recaptcha_key
export default function ResetPwd({setShowSidebar}) {
    
  const { enqueueSnackbar } = useSnackbar();

  const param = useParams();

  const navigate = useNavigate();

  const [captchaValue, setCaptchaValue] = useState(null);

  const recaptchaRef = React.useRef();

  const [errors, setErrors] = React.useState({});

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
    password_confirmation: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errMsg = {};
    
    errMsg.email = isStringEmpty(formData.email) ? 'Email is required' : '';
    if (!errMsg.email) {
        errMsg.email = !isEmailValid(formData.email) ? 'Email is not valid' : '';
    }

    errMsg.password = isStringEmpty(formData.password) ? 'Password is required' : '';

    errMsg.password_confirmation = isStringEmpty(formData.password_confirmation) ? 'Password confirmation is required' : '';

    if (formData.password !== formData.password_confirmation) {
      errMsg.password_confirmation = errMsg.password = 'Password and Password confirmation must be identical'
    }
    
    setErrors({...errMsg});

    return Object.values(errMsg).every(item => item === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!captchaValue) {
      enqueueSnackbar('Please pass google reCaptcha',  {variant: 'error'})
      return;  
    }
    
    if (!validateForm()) {
      enqueueSnackbar('Please fill in the form correctly',  {variant: 'error'})
      return;
    } 
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    data.set('token', param.token)

    Api.post('/customer/resetPwd', data)
      .then((data) => {
        if (data.code !== 1000) {
          recaptchaRef.current.reset();
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          enqueueSnackbar('Password reset successfully',  {variant: 'success'})
          navigate('/SignIn')
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  }

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };


  React.useEffect(() => {
    setShowSidebar(0)
    
  }, []);

  return (
    

    <Box component="main" sx={{ flexGrow: 1, pt: 10 }}>
    <Container component="main" maxWidth="xs">
        <Box
        sx={{
            // marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
        >
        
        <Typography component="h1" variant="h5">
            Reset Password
        </Typography>
        
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              onChange={handleChange}
              error={errors.email ? true : false}
              helperText={errors.email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              onChange={handleChange}
              error={errors.password ? true : false}
              helperText={errors.password}
              margin="normal"
              required
              fullWidth
              name="password"
              label="New password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handlePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              onChange={handleChange}
              error={errors.password_confirmation ? true : false}
              helperText={errors.password_confirmation}
              margin="normal"
              required
              fullWidth
              name="password_confirmation"
              label="Confirm password"
              type={showPassword ? 'text' : 'password'}
              id="password_confirmation"
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handlePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={ReCAPTCHA_SITE_KEY}
            onChange={handleCaptchaChange}
            />
            <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '16px' }}
            >
            Submit
            </Button>
        </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
    </Box>
  );
}


