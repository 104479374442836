import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Container, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ResponsiveDialog from '../components/ResponsiveDialog';


export default function UserInfo({setShowSidebar, setSelectedIndex}) {

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const [formData, setFormData] = React.useState({
        email: '',
        mobile: '',
        firstName: '',
        lastName: '',
        address: '',
        verified: ''
    });
    const [errors, setErrors] = React.useState({});

    const [isOpen, setIsOpen] = React.useState(false)

    const dialogTitle = 'Email verification'

    const dialogContent = 'Dear customer, Please verify your email'

    const handleConfirmCallback = () => {
        handleVerify()
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
    }

    const getUserInfo = () => {
        Api.get('/customer/me')
            .then(res => {
                const userData = {
                    email: res.data.email,
                    mobile: res.data.mobile,
                    firstName: res.data.first_name,
                    lastName: res.data.last_name,
                    address: res.data.address,
                    verified: res.data.email_verified_at ? true : false
                }
                setFormData(userData);
                setIsOpen(userData.verified ?  false  : true)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleVerify = () => {
        Api.get('/customer/sendVerifyEmail')
            .then(res => {
                if (res.code !== 1000) {
                    enqueueSnackbar(res.msg, {variant: 'error'})
                    return
                }
                enqueueSnackbar('The verification email has been sent', {variant: 'success'})
                setIsOpen(false)
            })
            .catch(err => {
                console.log(err);
                if (err.code === 'ERR_BAD_REQUEST') {
                    enqueueSnackbar('Please try again later', {variant: 'error'})
                }
            })
    }

    React.useEffect(() => {
        setShowSidebar(1)
        setSelectedIndex(1)
        getUserInfo()
    }, [])


    return (
        <Container component="main" maxWidth="lg" sx={{pb:13}}>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ flexGrow: 1, }} >
            <Toolbar />
            <ResponsiveDialog title={dialogTitle} 
                          content={dialogContent} 
                          isOpen={isOpen} 
                          setIsOpen={setIsOpen}
                          confirmCallback={handleConfirmCallback}
                          confirmText={'Send Email'}
                          />
            {/* <Typography variant="h6" gutterBottom>
                User Infomation
            </Typography> */}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                    disabled
                    error={errors.email ? true : false}
                    helperText={errors.email}
                    onChange={handleChange}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={formData.email}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            {formData.verified ? (
                                'Verified'
                            ) : (
                                <Button variant="contained" size="small" onClick={handleVerify}>
                                    Click to verify
                                </Button>
                            )}    
                            </InputAdornment>
                        ),
                    }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    error={errors.phone ? true : false}
                    helperText={errors.phone}
                    onChange={handleChange}
                    required
                    fullWidth
                    id="phone"
                    label="Phone"
                    name="mobile"
                    value={formData.mobile}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName}
                  onChange={handleChange}
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={formData.firstName}
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName}
                  onChange={handleChange}
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={formData.lastName}
                  InputLabelProps={{
                    shrink: true,
                }}
                />
              </Grid>
                <Grid item xs={12}>
                <TextField
                    required
                    id="address"
                    name="address"
                    label="Address line "
                    fullWidth
                    autoComplete="shipping address-line"
                    // variant="standard"
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={formData.address}
                />
                </Grid>
                
            </Grid>
            <Button onClick={handleSubmit} variant="contained" sx={{ mt: 3, ml: 1 }}>
                save
            </Button>
        </Box>
        </Container>
    );
}